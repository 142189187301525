import { observer } from "mobx-react";
import React from "react";
import { store } from "../../../../../store";
import VideoComponent from "./VideoComponent";

function DesignWidgetVideo(props) {
  const [widget, SetWidget] = React.useState();

  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });
    console.log(props);
    SetWidget(widgetActual);
  }, [props.widget]);

  return (
    <>
      {widget && (
        <VideoComponent
          datosVideo={{
            full: widget.fullscreen,
            url: widget.url,
            plataforma: widget.plataforma,
            ratio: widget.ratio,
          }}
        />
      )}
    </>
  );
}

export default observer(DesignWidgetVideo);
