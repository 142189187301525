import { Button, message, Popconfirm } from "antd";
import React from "react";
import { Api } from "../../api/configApi";

function BorrarDb() {
  const [cargando, setCargando] = React.useState(false);
  function eliminarTodo() {
    setCargando(true);
    Api("cuentas", "EliminarTodo").then((res) => {
      setCargando(false);
      message.success("Eliminación Completada");
      console.log(res);
    });
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", width: 500 }}>
      <span>Esta acción no tiene retroceso.</span>
      <Popconfirm
        title="¿Completamente Seguro?"
        okText="Borrar los afiliados"
        cancelText="Cancelar"
        onConfirm={eliminarTodo}
      >
        <Button loading={cargando} type="danger">
          Borrar los afiliados
        </Button>
      </Popconfirm>
    </div>
  );
}

export default BorrarDb;
