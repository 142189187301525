import React, { memo } from "react";

import { store } from "../../../../../store";
import { Divider, Drawer, Table, Image, Switch, Input } from "antd";

import { Api } from "../../../../../api/configApi";
import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/es";
import jwtDecode from "jwt-decode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import EditarPerfilAfiliado from "../perfilAfiliado/EditarPerfilAfiliado";
const { Search } = Input;
function DesignWidgetTabla(props) {
  const [widget, setWidget] = React.useState({});
  const [contenido, setContenido] = React.useState("");
  const [infoElemento, setInfoElemento] = React.useState({});
  const [cargando, setCargando] = React.useState(true);
  const [columnas, setColumnas] = React.useState([]);
  const [datos, setDatos] = React.useState([]);
  const [drawerPerfil, setDrawerPerfil] = React.useState(false);
  const [ideAfiliado, setIdeAfiliado] = React.useState("");
  const idAfiliadoActual = React.useRef("");
  const losDatos = React.useRef([]);

  React.useEffect(() => {
    if (drawerPerfil && idAfiliadoActual.current !== "") {
      Api("afiliados", "GetIde", { idAfiliado: idAfiliadoActual.current }).then(
        (res) => {
          console.log(res);
          setIdeAfiliado(res.ide);
        }
      );
    }
  }, [drawerPerfil]);

  function Buscar(Elwidget) {
    setCargando(true);
    let atrr = {};
    [
      ...store.losatributos.numbers,
      ...store.losatributos.texts,
      ...store.losatributos.dates,
    ].forEach((e) => {
      atrr["atributo_" + e._id] = e.nombre;
    });

    Api("widgets", "tabla", {
      idWidget: Elwidget._id,
      atributos: atrr,
    })
      .then((res) => {
        setCargando(false);
        if (res.datos.data.length > 0) {
          res.datos.data.forEach((e) => {
            if (e?.fecha) {
              e.fecha = (
                <Moment locale="es" local format="D MMM YYYY">
                  {e.fecha}
                </Moment>
              );
            }
          });
          setDatos(res.datos.data);
          losDatos.current = res.datos.data;
        }
      })
      .catch((er) => {
        setCargando(false);
        console.log(er);
      });
  }

  React.useEffect(() => {
    const widgetActual = store.widgets.find((wid) => {
      return wid._id === props._id;
    });
    if (widgetActual.listar === "listado") {
      var listado = store.listados.find((list) => {
        return list._id === widgetActual.listado;
      });
    }

    setWidget(widgetActual);
    var columnasNuevas = [];
    widgetActual.columnas.forEach((element) => {
      columnasNuevas.push({
        title: () => {
          if (element.split("_")[0] === "atributo") {
            return [
              ...store.losatributos.numbers,
              ...store.losatributos.texts,
              ...store.losatributos.dates,
            ].find((e) => e._id === element.split("_")[1]).nombre;
          } else {
            return element[0].toUpperCase() + element.slice(1);
          }
        },
        dataIndex: element,
        key: element,
        render: (e, not, as) => {
          return widgetActual.listar === "listado" ? (
            listado.campos.find((a) => a.NombreCampo == element)?.tipoCampo ===
            "url" ? (
              e ? (
                <a href={e} target="_blank" rel="noreferrer">
                  Link
                </a>
              ) : null
            ) : listado.campos.find((a) => a.NombreCampo == element)
                ?.tipoCampo === "imagen" ? (
              <Image
                style={{ display: e ? "" : "none", width: 50 }}
                src={e}
                alt="imagen"
              />
            ) : listado.campos.find((a) => a.NombreCampo == element)
                ?.tipoCampo === "switch" ? (
              <Switch disabled value={e} />
            ) : (
              <span>{e}</span>
            )
          ) : (
            <span>{e}</span>
          );
        },
      });
    });
    const token = jwtDecode(localStorage.getItem("token"));
    if (token.admin && widgetActual.tipoAfiliado) {
      columnasNuevas.push({
        title: "",
        dataIndex: "Acciones",
        key: "Acciones",
        render: (text, record) => (
          <FontAwesomeIcon
            style={{ cursor: "pointer" }}
            icon={faEye}
            onClick={() => {
              idAfiliadoActual.current = record._id;
              setDrawerPerfil(true);
              setInfoElemento(record);
            }}
          />
        ),
      });
    }

    setColumnas(columnasNuevas);
    Buscar(widgetActual);

    return () => {
      setWidget(null);
      setContenido("");
      setColumnas([]);
      setDatos([]);
      setDrawerPerfil(false);
      setInfoElemento({});
      columnasNuevas = [];
    };
  }, [props]);

  function onSearch(valor) {
    if (valor) {
      const widgetActual = store.widgets.find((wid) => {
        return wid._id === props._id;
      });
      const Lascolumnas = widgetActual.columnas;
      const datosFiltrados = losDatos.current.filter((e) => {
        var encontrado = false;
        Lascolumnas.forEach((col) => {
          if (
            e[col] &&
            e[col].toString().toLowerCase().includes(valor.toLowerCase())
          ) {
            encontrado = true;
          }
        });
        return encontrado;
      });
      setDatos(datosFiltrados);
    } else {
      setDatos(losDatos.current);
    }
  }

  return (
    <div style={{ padding: 20 }}>
      <Drawer
        width={800}
        visible={drawerPerfil}
        title={`Perfil ide ${ideAfiliado}`}
        onClose={() => {
          setDrawerPerfil(false);
          Buscar(widget);
        }}
        destroyOnClose
      >
        <EditarPerfilAfiliado infoElemento={infoElemento} />
      </Drawer>
      <div style={estilos.contenido}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ marginLeft: 10, fontSize: 15 }}>
            {widget.titulo}
          </strong>
          <Search
            placeholder="Buscar contenido"
            enterButton="Buscar"
            size="large"
            type="search"
            onSearch={onSearch}
            style={{ marginTop: 10, width: 500 }}
          />
        </div>
        <Table
          loading={cargando}
          style={{ marginTop: 10 }}
          dataSource={datos}
          columns={columnas}
        />
      </div>
    </div>
  );
}
const estilos = {
  contenido: {},
};
export default memo(DesignWidgetTabla);
