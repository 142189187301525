import Boton from "../design/elementos/boton";
import React, { useState } from "react";
import { Drawer, Button } from "antd";
import TiposWidgetsComponente from "./tiposWidgetsComponente";

function CrearNuevoWidgetLista() {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          marginBottom: 10,
        }}
      >
        <Boton onClick={showDrawer} texto="crear Widget" />
        <Drawer
          title="Tipos de Widgets"
          placement="right"
          closable={false}
          onClose={onClose}
          width={400}
          visible={visible}
        >
          <TiposWidgetsComponente />
        </Drawer>
      </div>
    </div>
  );
}

export default CrearNuevoWidgetLista;
