import { Form, Input, message, Select } from "antd";
import React from "react";
import { Api } from "../../../../api/configApi";
import Boton from "../../../../components/design/elementos/boton";
import { store } from "../../../../store";
import ZonasHorarias from "../../../../helpers/zonasHorarias";
import ListadoFormDisparador from "./tiposDisparadorForm/ListadoFormDisparados";

const { Option } = Select;
function FormularioCrearDisparador({
  idFlujo,
  setVisible,
  CargarDisparador,
  disparador,
}) {
  const [form] = Form.useForm();
  const [tagsProductos, setTagsProductos] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (
      store.cuenta.modulos.find((e) => {
        return e.nombre === "woocommerce";
      })
    ) {
      Api("modulos", "woocommerce", { consulta: "GetTags" }).then((res) => {
        console.log(res);
        setTagsProductos(res);
      });
    }
    if (disparador) {
      form.setFieldsValue(disparador);
    }
  }, []);
  function crearActualizarDisparador(values) {
    if (values.variablesDisparador) {
      values.variablesDisparador.forEach((e) => {
        // agregar _id aleatorio
        e.yaGuardado = true;
      });
    }

    setLoading(true);
    Api(
      "disparadores",
      "InsertAndUpdate",
      { cuenta: store.cuentaactiva, flujo: idFlujo },
      values
    )
      .then((res) => {
        setLoading(false);
        if (res.success) {
          setVisible(false);
          CargarDisparador();
        } else {
          message.error(res.msg || "No pudimos crear el disparador");
        }
      })
      .catch((er) => {
        setLoading(false);
        message.error("No pudimos crear el disparador");
      });
  }
  return (
    <Form onFinish={crearActualizarDisparador} form={form} layout="vertical">
      <Form.Item
        colon={false}
        name="tipo"
        rules={[{ required: true, message: "Campo obligatorio" }]}
        label="¿Cuando?"
      >
        <Select>
          <Option value="registro">Cada nuevo afiliado registrado</Option>
          <Option value="compra">Al recibir una Compra</Option>
          <Option value="cron">Cada X tiempo</Option>
          <Option value="listados">Listados</Option>
        </Select>
      </Form.Item>
      <Form.Item hidden name="_id"></Form.Item>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          return getFieldValue().tipo === "compra" ? (
            <>
              <Form.Item name="tagsProductos">
                <Form.Item
                  name={["tagsProductos", "tags"]}
                  label="Solo aplica para producto con las tags"
                  style={{
                    display: "flex",
                    marginBottom: 20,
                    flexDirection: "column",
                  }}
                >
                  <Select mode="multiple">
                    {tagsProductos.length > 0 &&
                      tagsProductos.map((tag) => {
                        return (
                          <Option key={tag.name} value={tag.id}>
                            {tag.name}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                  {({ getFieldValue }) => {
                    if (getFieldValue().hasOwnProperty("tagsProductos")) {
                      return (
                        getFieldValue().tagsProductos["tags"].length > 0 && (
                          <Form.Item
                            label="Condicionales Tags"
                            name={["tagsProductos", "filtro"]}
                            initialValue="uno"
                          >
                            <Select>
                              <Option value="allProductsAllTags">
                                Todos deben tener todas las Tags
                              </Option>
                              <Option value="allProductsOneTag">
                                Todos deben tener al menos una Tag
                              </Option>
                              <Option value="oneProductOneTag">
                                Al menos un producto debe tener una tag
                              </Option>
                              <Option value="oneProductAllTags">
                                Al menos un producto debe tener todas las tags
                              </Option>
                            </Select>
                          </Form.Item>
                        )
                      );
                    }
                  }}
                </Form.Item>
              </Form.Item>

              <Form.Item
                name="montoMinimo"
                label="Monto Minimo"
                style={{
                  display: "flex",
                  marginBottom: 20,
                  flexDirection: "column",
                }}
                initialValue=""
              >
                <Input type="number" />
              </Form.Item>
              <Form.Item
                name="montoMaximo"
                label="Monto Máximo"
                style={{
                  display: "flex",
                  marginBottom: 20,
                  flexDirection: "column",
                }}
                initialValue=""
              >
                <Input type="number" />
              </Form.Item>
            </>
          ) : getFieldValue().tipo === "cron" ? (
            <>
              <Form.Item name="dia" initialValue="todoslosdias">
                <Select>
                  <Option value="todoslosdias">Todos los dias</Option>
                  <Option value="primerdia">Primer dia del mes</Option>
                  <Option value="1y15">1 Y 15 de cada mes</Option>
                  <Option value="1">Todos los lunes</Option>
                  <Option value="2">Todos los martes</Option>
                  <Option value="3">Todos los miercoles</Option>
                  <Option value="4">Todos los jueves</Option>
                  <Option value="5">Todos los viernes</Option>
                  <Option value="6">Todos los sabados</Option>
                  <Option value="0">Todos los domingos</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="hora"
                label="Hora"
                rules={[{ required: true, message: "Campo obligatorio" }]}
              >
                <Select showSearch>
                  {[...Array(24)].map((e, i) => {
                    return <Option value={i}>{i}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name="minuto"
                label="Minuto"
                rules={[{ required: true, message: "Campo obligatorio" }]}
              >
                <Select showSearch>
                  {[...Array(60)].map((e, i) => {
                    return <Option value={i}>{i}</Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name="zonaHoraria"
                label="Zona Horaria"
                rules={[{ required: true, message: "Campo obligatorio" }]}
              >
                <Select showSearch={true}>
                  {ZonasHorarias.map((e) => (
                    <Option value={e} key={e}>
                      {e}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          ) : (
            getFieldValue().tipo === "listados" && (
              <ListadoFormDisparador form={form} disparador={disparador} />
            )
          );
        }}
      </Form.Item>
      <Boton
        style={{ marginTop: 10 }}
        texto={disparador ? "Actualizar" : "Guardar"}
        type="primary"
        htmlType="submit"
        loading={loading}
      />
    </Form>
  );
}

export default FormularioCrearDisparador;
