import {
  faCaretRight,
  faCog,
  faPencilAlt,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Drawer,
  Dropdown,
  notification,
  Popconfirm,
  Space,
  Menu,
  message,
  Skeleton,
} from "antd";
import { observer } from "mobx-react";
import React from "react";
import { useHistory, useParams } from "react-router";
import { Api } from "../../../api/configApi";
import Boton from "../../../components/design/elementos/boton";
import {
  setPensando,
  setStoreActualizarLaEstructura,
  setStoreEtapas,
  store,
} from "../../../store";
import FormularioCrearDisparador from "./componentes/FormularioCrearDisparador";
import CardEtapa from "./cardEtapa";
import ModalResultadosEtapa from "./componentes/pulpitos/modalResultadosEtapa";

function RenderEtapas() {
  const [etapas, setEtapas] = React.useState([]);
  const [disparador, setDisparador] = React.useState();
  const [visible, setVisible] = React.useState(false);
  const [cargando, setCargando] = React.useState(true);
  const params = useParams();
  const history = useHistory();

  function eliminarFlujo() {
    if (params.id === params.idgrupo)
      return message.error("No puedes eliminar el flujo base");
    if (store.etapas.length > 0)
      return message.error("Debes eliminar primero las etapas");
    setPensando(true);
    Api(
      "cuentas",
      "DeleteFlujo",
      { _id: store.cuentaactiva },
      { flujo: params.id }
    ).then((res) => {
      if (res.success) {
        setPensando(false);
        message.success("Flujo Eliminado");
        setStoreActualizarLaEstructura();
        history.push(params.idgrupo);
      } else {
        message.error("Algo no salio bien");
      }
    });
  }

  function CargarDisparador() {
    Api("disparadores", "Get", { idFlujo: params.id }).then((res) => {
      if (res) {
        setDisparador(res.datos);
      }
    });
  }
  function GetEtapas() {
    let mounted = true;
    setPensando(true);
    CargarDisparador();
    setCargando(true);
    Api("etapas", "Get", {
      cuenta: store.cuentaactiva,
      flujo: params.id,
      grupo: params.idgrupo,
    })
      .then((etapas) => {
        console.timeEnd("Etapas");
        if (mounted) {
          setStoreEtapas(etapas);
          setPensando(false);
        }
        setCargando(false);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => (mounted = false);
  }
  React.useEffect(() => {
    GetEtapas();
  }, [params.id]);

  React.useEffect(() => {}, [store.etapas]);

  function ListarEtapas() {
    if (cargando) {
      return <span>Cargando...</span>;
    } else {
      if (!store.pensando && store.etapas.length > 0) {
        return store.etapas
          .sort((e, b) => e.prioridad - b.prioridad)
          .map((etapa, index) => {
            return (
              <CardEtapa
                key={"id" + index}
                index={index}
                disparador={disparador}
                etapa={etapa}
                cantidadEtapas={store.etapas.length}
                GetEtapas={GetEtapas}
              />
            );
          });
      } else if (!store.pensando && etapas.length === 0) {
        return <span>No tienes etapas aún en este flujo.</span>;
      } else {
        return <></>;
      }
    }
  }

  const CargandoEtapas = observer(() => {
    return (
      <>
        {!cargando ? (
          <>
            <Drawer
              placement="right"
              width={800}
              visible={visible}
              onClose={() => {
                setVisible(false);
              }}
              title="Nuevo Disparador"
            >
              <FormularioCrearDisparador
                idFlujo={params.id}
                setVisible={setVisible}
                CargarDisparador={CargarDisparador}
                disparador={disparador}
              />
            </Drawer>
            {disparador ? (
              <div
                style={{
                  display: "flex",
                  marginBottom: 35,
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <strong
                  style={{ fontSize: 20, marginLeft: 10, marginRight: 5 }}
                >
                  Disparador:
                </strong>
                <span style={{ fontSize: 20, marginRight: 5 }}>
                  {disparador.tipo} <span style={{ opacity: 0.3 }}>|</span>
                </span>
                <Dropdown
                  trigger={["click"]}
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={() => {
                          setVisible(true);
                        }}
                      >
                        Editar Disparador
                      </Menu.Item>
                      <Menu.Item>Cambiar Nombre del flujo</Menu.Item>
                      {params.idgrupo !== params.id && (
                        <Menu.Item
                          style={{ color: "red" }}
                          onClick={eliminarFlujo}
                        >
                          Eliminar Flujo
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                >
                  <FontAwesomeIcon
                    style={{ fontSize: 20, cursor: "pointer" }}
                    icon={faCog}
                  />
                </Dropdown>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  maxWidth: 400,
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  margin: "auto",
                  marginTop: 20,
                }}
              >
                <strong style={{ fontSize: 30 }}>Disparador</strong>
                <p style={{ fontWeight: 200 }}>
                  Un disparador te permite procesar las etapas en momentos
                  especificos como cuando ocurre una compra o en ciertos dias a
                  la semana
                </p>
                <Boton
                  onClick={() => {
                    setVisible(true);
                  }}
                  texto="Crear Disparador"
                />
                <span
                  style={{ marginTop: 10, cursor: "pointer" }}
                  onClick={eliminarFlujo}
                >
                  Eliminar Flujo
                </span>
              </div>
            )}
            {disparador && <ListarEtapas />}
          </>
        ) : (
          <>
            <Skeleton.Button
              active={true}
              size="large"
              shape="round"
              style={{ height: 70 }}
              block={true}
            />
            <Skeleton.Button
              active={true}
              size="default"
              shape="round"
              style={{ marginTop: 10, width: 300 }}
              block={true}
            />
          </>
        )}
      </>
    );
  });

  return <CargandoEtapas />;
}

export default observer(RenderEtapas);
