import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Space,
  Switch,
} from "antd";
import React from "react";
import { store } from "../../../../../store";

const { Option } = Select;

function ListadoFormDisparador({ form, disparador }) {
  return (
    <>
      <Form.Item
        name="listado"
        label="Listado"
        rules={[{ required: true, message: "Campo Obligatorio" }]}
      >
        <Select>
          {store.listados.map((e) => (
            <Option value={e._id} key={e._id}>
              {e.nombre}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, curValues) =>
          prevValues.Listado !== curValues.Listado
        }
      >
        {({ getFieldValue }) => {
          return (
            <>
              <Form.Item
                name="tipoEvento"
                label="Solo si se"
                rules={[{ required: true, message: "Campo obligatorio" }]}
              >
                <Select>
                  <Option value="newItem">Agrega un nuevo Item</Option>
                  <Option value="updateItem">Actualiza un item</Option>
                  <Option value="deleteItem">Elimina un item</Option>
                </Select>
              </Form.Item>
              {store.listados
                .find((e) => e._id === getFieldValue("listado"))
                ?.campos.map((e) => {
                  return (
                    e.tipoCampo !== "imagen" &&
                    e.tipoCampo !== "fecha" && (
                      <Space>
                        <Form.Item
                          name={["camposListado", e.NombreCampo, "validacion"]}
                          label={`${e.NombreCampo} es`}
                          style={{ width: 200 }}
                          rules={[
                            {
                              required: e.obligatorio,
                              message: "Campo obligatorio",
                            },
                          ]}
                          initialValue="igualOdiferente"
                        >
                          <Select>
                            <Option value="igualOdiferente">
                              Igual o diferente a
                            </Option>
                            <Option value="igual">Igual a</Option>
                            <Option value="diferente">Diferente a</Option>
                          </Select>
                        </Form.Item>
                        <Form.Item
                          label="_"
                          name={["camposListado", e.NombreCampo, "valor"]}
                          initialValue={e.NombreCampo}
                          valuePropName={
                            e.tipoCampo === "switch" ? "checked" : "value"
                          }
                          style={{ width: 400 }}
                        >
                          {e.tipoCampo === "texto" ? (
                            <Input />
                          ) : e.tipoCampo === "multiple" ? (
                            <Select>
                              {e.opciones.map((r) => (
                                <Option value={r.opcion}>{r.opcion}</Option>
                              ))}
                            </Select>
                          ) : e.tipoCampo === "numero" ? (
                            <InputNumber style={{ width: 400 }} type="number" />
                          ) : e.tipoCampo === "fecha" ? (
                            <DatePicker showTime style={{ width: "100%" }} />
                          ) : (
                            e.tipoCampo === "switch" && <Switch />
                          )}
                        </Form.Item>
                      </Space>
                    )
                  );
                })}
              <Form.List name="variablesDisparador">
                {(fields, { add, remove }) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        background: "rgb(243 243 243)",
                        padding: 20,
                        borderRadius: 5,
                      }}
                    >
                      {fields.map((field, index) => (
                        <Space>
                          <div>
                            <FontAwesomeIcon
                              onClick={() => {
                                if (
                                  store.etapas.some((e) =>
                                    e.tagsConfig.lastags.some(
                                      (a) =>
                                        a.tipo.split(" ")[0] === "variable" &&
                                        a.valor ===
                                          form.getFieldValue(
                                            "variablesDisparador"
                                          )[index].nombreVariable
                                    )
                                  )
                                ) {
                                  message.error(
                                    "Estas usando esta variable. Primero elimínala de las etapas"
                                  );
                                } else {
                                  remove(field.name);
                                }
                              }}
                              icon={faMinusCircle}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                          <Form.Item
                            {...field.layout}
                            label="Nombre Variable"
                            rules={[
                              { required: true, message: "Campo obligatorio" },
                              {
                                validator: (rule, value) => {
                                  if (
                                    form
                                      .getFieldValue("variablesDisparador")
                                      .filter(
                                        (v) =>
                                          v?.nombreVariable.trim() ===
                                          value.trim()
                                      ).length > 1
                                  ) {
                                    return Promise.reject(
                                      "Ya existe una variable con ese nombre"
                                    );
                                  } else {
                                    return Promise.resolve();
                                  }
                                },
                              },
                            ]}
                            key={[field.name, "nombreVariable"]}
                            name={[field.name, "nombreVariable"]}
                          >
                            <Input
                              className="inputBlanco"
                              disabled={
                                form.getFieldValue("variablesDisparador")[index]
                                  ?.yaGuardado || false
                              }
                            />
                          </Form.Item>
                          <Form.Item
                            name={[field.name, "tipoVariable"]}
                            {...field.layout}
                            label="Tipo"
                            rules={[
                              { required: true, message: "Campo obligatorio" },
                            ]}
                            key={[field.name, "tipoVariable"]}
                          >
                            <Select
                              disabled={
                                form.getFieldValue("variablesDisparador")[index]
                                  ?.yaGuardado || false
                              }
                              style={{ minWidth: 200 }}
                              showSearch
                            >
                              <Option value="campoListado">
                                Campo Listado
                              </Option>
                            </Select>
                          </Form.Item>
                          <Form.Item noStyle shouldUpdate>
                            {({ getFieldValue }) => {
                              return (
                                <Form.Item
                                  name={[field.name, "elcampo"]}
                                  label="Campo"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Campo obligatorio",
                                    },
                                    {
                                      validator: (rule, value) => {
                                        if (
                                          !store.listados
                                            .find(
                                              (e) =>
                                                e._id ===
                                                getFieldValue("listado")
                                            )
                                            ?.campos.some(
                                              (e) => e.NombreCampo === value
                                            )
                                        ) {
                                          return Promise.reject(
                                            "Esta no es una opción valida"
                                          );
                                        } else {
                                          return Promise.resolve();
                                        }
                                      },
                                    },
                                    {
                                      validator: (rule, value) => {
                                        if (
                                          form
                                            .getFieldValue(
                                              "variablesDisparador"
                                            )
                                            .filter(
                                              (v) =>
                                                v?.elcampo?.trim() ===
                                                value.trim()
                                            ).length > 1
                                        ) {
                                          return Promise.reject(
                                            "Este campo ya fue asignado a una variable"
                                          );
                                        } else {
                                          return Promise.resolve();
                                        }
                                      },
                                    },
                                  ]}
                                >
                                  <Select
                                    disabled={
                                      form.getFieldValue("variablesDisparador")[
                                        index
                                      ]?.yaGuardado || false
                                    }
                                    style={{ minWidth: 200 }}
                                    showSearch
                                  >
                                    {store.listados
                                      .find(
                                        (e) =>
                                          e._id === getFieldValue("listado")
                                      )
                                      ?.campos.map((e) => {
                                        return (
                                          <Option
                                            value={e.NombreCampo}
                                            key={e.NombreCampo}
                                          >
                                            {e.NombreCampo}
                                          </Option>
                                        );
                                      })}
                                  </Select>
                                </Form.Item>
                              );
                            }}
                          </Form.Item>
                        </Space>
                      ))}
                      <Form.Item noStyle>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add();
                          }}
                          style={{ width: "60%", alignSelf: "center" }}
                        >
                          Agregar Variable
                        </Button>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>
            </>
          );
        }}
      </Form.Item>
    </>
  );
}

export default ListadoFormDisparador;
