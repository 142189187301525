import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Drawer, message, Popconfirm, Table } from "antd";
import React from "react";

import CamposFechaComponente from "./componentes/CamposFechaComponente";
import CampoNumeroComponente from "./componentes/CampoNumeroComponente";
import CampoSwitchComponente from "./componentes/CampoSwitchComponente";
import CampoUrlComponente from "./componentes/CampoUrlComponente";
import CampoTextoComponente from "./componentes/campoTextoComponente";
import CampoImagenComponente from "./componentes/CampoImagenComponente";
import CampoOpcionMultipleComponente from "./componentes/CampoOpcionMultipleComponente";
import FormAgregarItemListado from "./FormAgregarItemListado";
import { store } from "../../../../../../store";
import { Api } from "../../../../../../api/configApi";

function VerItems(props) {
  const [items, setItems] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [columns, setColums] = React.useState([
    {
      title: "Documento",
      dataIndex: "documento",
      key: "documento",
    },
    {
      title: "Fecha",
      dataIndex: "fecha",
      key: "fecha",
    },
    {
      title: "",
      dataIndex: "acciones",
      key: "acciones",
      render: (e, not) => {},
    },
  ]);

  function Get() {
    Api(
      "listados",
      "GetItemsAll",
      {
        afiliado: props.afiliado,
        listado: store.listados.find((e) => e.nombre === props.listado)._id,
      },
      {},
      "https://f399-181-132-3-197.ngrok.io"
    ).then((res) => {
      setItems(res);
    });
  }

  function eliminarItem(it) {
    Api(
      "listados",
      "DeleteItem",
      { _id: it._id },
      {},
      "https://f399-181-132-3-197.ngrok.io"
    ).then((res) => {
      if (res?.success) {
        message.success("Item eliminado");
        Get();
      } else {
        message.error("Algo no salio bien");
      }
    });
  }

  React.useEffect(() => {
    const newCol = [];
    store.listados
      .find((e) => e.nombre === props.listado)
      .campos.forEach((e) => {
        newCol.push({
          title: e.NombreCampo,
          dataIndex: e.NombreCampo,
          key: e.NombreCampo,
          render: (valor, en) => {
            switch (e.tipoCampo) {
              case "fecha":
                if (valor) {
                  return (
                    <CamposFechaComponente
                      valor={valor}
                      id={en._id}
                      nombreCampo={e.NombreCampo}
                      idListado={en.listado_id}
                      Get={Get}
                    />
                  );
                } else {
                  return (
                    <CamposFechaComponente
                      valor="1970-01-01T18:34:27.702+00:00"
                      id={en._id}
                      nombreCampo={e.NombreCampo}
                      idListado={en.listado_id}
                      Get={Get}
                    />
                  );
                }
                break;
              case "numero":
                if (valor) {
                  return (
                    <CampoNumeroComponente
                      valor={valor}
                      id={en._id}
                      nombreCampo={e.NombreCampo}
                      idListado={en.listado_id}
                      Get={Get}
                    />
                  );
                } else {
                  return (
                    <CampoNumeroComponente
                      valor={NaN}
                      id={en._id}
                      nombreCampo={e.NombreCampo}
                      idListado={en.listado_id}
                      Get={Get}
                    />
                  );
                }
                break;
              case "switch":
                return (
                  <CampoSwitchComponente
                    valor={valor}
                    id={en._id}
                    nombreCampo={e.NombreCampo}
                    idListado={en.listado_id}
                    Get={Get}
                  />
                );
                break;
              case "url":
                if (valor) {
                  return (
                    <CampoUrlComponente
                      valor={valor}
                      id={en._id}
                      nombreCampo={e.NombreCampo}
                      idListado={en.listado_id}
                      Get={Get}
                    />
                  );
                } else {
                  return (
                    <CampoUrlComponente
                      valor=""
                      id={en._id}
                      nombreCampo={e.NombreCampo}
                      idListado={en.listado_id}
                      Get={Get}
                    />
                  );
                }
                break;
              case "texto":
                if (valor) {
                  return (
                    <CampoTextoComponente
                      valor={valor}
                      id={en._id}
                      nombreCampo={e.NombreCampo}
                      idListado={en.listado_id}
                      Get={Get}
                    />
                  );
                } else {
                  return (
                    <CampoTextoComponente
                      valor=""
                      id={en._id}
                      nombreCampo={e.NombreCampo}
                      idListado={en.listado_id}
                      Get={Get}
                    />
                  );
                }
                break;
              case "imagen":
                if (valor) {
                  return (
                    <CampoImagenComponente
                      valor={valor}
                      id={en._id}
                      nombreCampo={e.NombreCampo}
                      idListado={en.listado_id}
                      Get={Get}
                    />
                  );
                } else {
                  return (
                    <CampoImagenComponente
                      valor=""
                      id={en._id}
                      nombreCampo={e.NombreCampo}
                      idListado={en.listado_id}
                      Get={Get}
                    />
                  );
                }
                break;
              case "multiple":
                if (valor) {
                  return (
                    <CampoOpcionMultipleComponente
                      opciones={e.opciones}
                      opcionDefecto={valor}
                      id={en._id}
                      nombreCampo={e.NombreCampo}
                      idListado={en.listado_id}
                      Get={Get}
                    />
                  );
                } else {
                  return (
                    <CampoOpcionMultipleComponente
                      opciones={e.opciones}
                      opcionDefecto=""
                      id={en._id}
                      nombreCampo={e.NombreCampo}
                      idListado={en.listado_id}
                      Get={Get}
                    />
                  );
                }
                break;
              default:
                return "caso por defecto";
                break;
            }
          },
        });
      });
    newCol.push({
      title: "",
      dataIndex: "table_trash",
      key: "table_trash",
      render: (e, it) => (
        <Popconfirm
          title="No podrás recuperar este item de nuevo."
          onConfirm={() => eliminarItem(it)}
        >
          <FontAwesomeIcon icon={faTrash} style={{ cursor: "pointer" }} />
        </Popconfirm>
      ),
    });
    setColums(newCol);
    Get();
  }, [props.listado]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "right",
          marginBottom: 10,
          marginRight: 5,
        }}
      >
        <Button onClick={() => setVisible(true)}>Agregar</Button>
      </div>
      <Table scroll={{ x: 800 }} columns={columns} dataSource={items} />
      <Drawer
        onClose={() => setVisible(false)}
        visible={visible}
        title="Agregar Item"
        destroyOnClose
      >
        <FormAgregarItemListado
          setVisible={setVisible}
          afiliado={props.afiliado}
          listado={props.listado}
          Get={Get}
        />
      </Drawer>
    </>
  );
}

export default VerItems;
