import React from "react";

const VideoComponent = (props) => {
  const [codigoYoutube, setCodigoYoutube] = React.useState("");
  const refCodigoYoutube = React.useRef("");
  const [codigoVimeo, setCodigoVimeo] = React.useState("");
  const refCodigoVimeo = React.useRef("");
  const refFullScreen = React.useRef(props.datosVideo.full);
  const refFullOpcion = React.useRef("");
  const [altoVideo, setAltoVideo] = React.useState();
  const refAltoVideo = React.useRef();

  const formatearURLYoutube = () => {
    let url = props.datosVideo.url;
    url = url.split("");
    let index = url.indexOf("=");
    let codigoVideoYoutube = [];
    url.forEach((letra, i) => {
      if (i > index) {
        codigoVideoYoutube.push(letra);
      }
    });
    codigoVideoYoutube = codigoVideoYoutube.join("");
    refCodigoYoutube.current = codigoVideoYoutube;
    setCodigoYoutube(refCodigoYoutube.current);
    // console.log(refCodigoYoutube.current);
  };

  const formatearURLVimeo = () => {
    let url = props.datosVideo.url;
    url = url.split("");
    let codigoVimeo = url.slice(18);
    refCodigoVimeo.current = codigoVimeo.join("");
    setCodigoVimeo(refCodigoVimeo.current);
  };

  const fullscreen = () => {
    refFullScreen.current
      ? (refFullOpcion.current = "fullscreen")
      : (refFullOpcion.current = "");
  };

  const dimensionVideo = () => {
    let ancho = document.querySelector(".padreVideo");
    // console.log(ancho.clientWidth)
    switch (props.datosVideo.ratio) {
      case "16:9":
        console.log("Aja..");
        refAltoVideo.current = ancho.clientWidth / 1.25;
        setAltoVideo(refAltoVideo.current);
        break;
      case "4:3":
        refAltoVideo.current = ancho.clientWidth / 1.33;
        setAltoVideo(refAltoVideo.current);
        break;
      case "14:9":
        refAltoVideo.current = ancho.clientWidth / 1.55;
        setAltoVideo(refAltoVideo.current);
        break;
      case "37:20":
        refAltoVideo.current = ancho.clientWidth / 1.85;
        setAltoVideo(refAltoVideo.current);
        break;
      case "13:6":
        refAltoVideo.current = ancho.clientWidth / 2.16;
        setAltoVideo(refAltoVideo.current);
        break;
      default:
        refAltoVideo.current = ancho.clientWidth / 1.25;
        setAltoVideo(refAltoVideo.current);
    }
    console.log(refAltoVideo.current);
  };

  React.useEffect(() => {
    console.log(props);

    fullscreen();
    if (props.datosVideo.plataforma === "YouTube") {
      formatearURLYoutube();
    } else if (props.datosVideo.plataforma === "Vimeo") {
      formatearURLVimeo();
    }
    dimensionVideo();
  }, []);

  return props.datosVideo.plataforma === "YouTube" ? (
    <div
      style={{
        padding: "10px",
        borderRadius: "10px",
      }}
      className="padreVideo"
    >
      <iframe
        width="100%"
        height={`${altoVideo}`}
        src={`https://www.youtube.com/embed/${codigoYoutube}`}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen={refFullScreen.current}
      ></iframe>
    </div>
  ) : props.datosVideo.plataforma === "Vimeo" ? (
    <div
      style={{
        padding: "10px",
        borderRadius: "10px",
      }}
      className="padreVideo"
    >
      <iframe
        src={`https://player.vimeo.com/video/${codigoVimeo}?h=ce693d66c6`}
        width="100%"
        height={`${altoVideo}`}
        frameborder="0"
        title="Vimeo video player"
        allow="autoplay; picture-in-picture"
        allowFullScreen={refFullScreen.current}
      ></iframe>
    </div>
  ) : (
    props.datosVideo.plataforma === "MP4" && (
      <div
        style={{
          padding: "10px",
          borderRadius: "10px",
        }}
        className="padreVideo"
      >
        <video
          width="100%"
          height={`${altoVideo}`}
          src={props.datosVideo.url}
          allowFullScreen={refFullScreen.current}
          controls="play"
          
        />
        
      </div>
    )
  );
};

export default VideoComponent;
