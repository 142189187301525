import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer, message, Popconfirm, Space, Table } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { Api } from "../../../../api/configApi";
import Boton from "../../../../components/design/elementos/boton";
import { setStoreActualizarLaEstructura, store } from "../../../../store";
import FormCrearListados from "./formCrearListado";

function RenderListados() {
  const [drawerCrearListado, setDrawerCrearListado] = React.useState(false);
  const [listadoActual, setListadoActual] = React.useState(null);

  function EliminarListado(listado) {
    Api("cuentas", "puedoEliminarlo", {
      tipo: "listado",
      listado: listado._id,
    }).then((res) => {
      if (res.autorizado) {
        Api("listados", "DeleteListado", { listado: listado._id }).then(
          (res) => {
            if (res.success) {
              message.success("Listado eliminado");
              setStoreActualizarLaEstructura(true);
            } else {
              message.error("No se pudo eliminar el listado");
            }
          }
        );
      } else {
        message.error(res.mensaje);
      }
    });
  }
  const columns = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "",
      dataIndex: "acciones",
      key: "acciones",
      render: (e, not) => {
        return (
          <Space size="large">
            <FontAwesomeIcon
              onClick={() => {
                setListadoActual(not);
                setDrawerCrearListado(true);
              }}
              style={{ cursor: "pointer" }}
              icon={faEdit}
            />
            <Popconfirm
              title={
                <div style={{ maxWidth: 200 }}>
                  Eliminaremos <strong>TODOS</strong> los items asignados a este
                  listado. <strong>No podrás recuperarlos.</strong>
                </div>
              }
              okText="Si, eliminar TODO"
              okType="danger"
              cancelText="Cancelar"
              onConfirm={() => EliminarListado(not)}
            >
              <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTrash} />
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  return (
    <div>
      <Drawer
        title="Listado"
        visible={drawerCrearListado}
        onClose={() => {
          setDrawerCrearListado(false);
        }}
        width={900}
      >
        <FormCrearListados
          listadoActual={listadoActual}
          setDrawerCrearListado={setDrawerCrearListado}
        />
      </Drawer>
      <div style={{ textAlign: "right", marginBottom: 10 }}>
        <Boton
          onClick={() => {
            setListadoActual(null);
            setDrawerCrearListado(true);
          }}
          texto="Crear Listado"
        />
      </div>
      <Table dataSource={store.listados} columns={columns} />
    </div>
  );
}

export default observer(RenderListados);
