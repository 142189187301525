import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import listafiltros from "../../../api/flujos/filtros/configFiltrosDisparadores.json";

function ListarFiltrosDisparadores(props) {
  const [opcionesdefiltros, setOpcionesdefiltros] = React.useState([]);
  React.useEffect(() => {
    console.log(props.disparadoractual);
    setOpcionesdefiltros(listafiltros[props.disparadoractual]);
  }, []);
  return (
    <div style={{minWidth: 180}}>
      <FontAwesomeIcon
        style={{
          fontSize: 15,
          marginBottom: 10,
          color: "#000",
          cursor: "pointer",
        }}
        icon={faArrowLeft}
        onClick={() => {
          props.setVistaactiva(0);
        }}
      />
      <div>
        {opcionesdefiltros.map((res) => {
          return (
            <div onClick={ ()=>{ props.setVistaactiva(2); props.setFiltroActual(res) } } key={res.id} style={{padding: 5, border: "1px solid rgb(219, 223, 234)", marginTop: 5, cursor: "pointer"}}>
              <span style={{fontSize: 16, fontWeight: 200}}>{res.nombre}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default ListarFiltrosDisparadores;
