import { Button, Drawer } from "antd";
import React from "react";
import { Api } from "../../api/configApi";
import { store } from "../../store";
import Boton from "../design/elementos/boton";
import CamposRequeridosModuloComponente from "./CamposRequeridosModuloComponente";

function CardModuloComponente(props) {
  const { modulo, alcanzoLimite } = props;
  const [visible, setVisible] = React.useState(false);
  function instalarModulo() {

  }
  function verificarModulo() {
    if (modulo.campos.length > 0) {
      setVisible(true);
    } else {
      Api("modulosDisponibles", "InstalarModulo", { cuenta: store.cuentaactiva }, { idmodulo: modulo._id, campos: modulo.campos }).then(res => {
        console.log(res);
      }).catch(err => {
        console.log(err);
      })
    }
  }
  function onClose() { setVisible(false); props.onClose() }
  return (
    <div
      style={{
        border: "1px solid rgb(230 230 230)",
        display: "flex",
        flexDirection: "column",
        padding: 10,
        borderRadius: 5,
        maxWidth: 300,
        margin: 10
      }}
    >

      <Drawer
        title="Campos Requeridos"
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
        width={800}
      >
        <CamposRequeridosModuloComponente setVisible={onClose} modulo={modulo} />
      </Drawer>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: 10 }}>
        <strong style={{ marginRight: 20 }}>{modulo.nombre}</strong>
        <span style={{ background: "rgb(0 160 86)", color: "#fff", padding: 5, borderRadius: 5, fontSize: 10 }}>Versión: {modulo._v}</span>
      </div>

      <span style={{ fontWeight: 200, fontSize: 12 }}>
        {modulo.descripcion}
      </span>
      {alcanzoLimite ? <Button disabled  style={{ marginTop: 10, marginBottom: 5 }} >Limite Alcanzado</Button> : <Boton onClick={verificarModulo} texto="Instalar" style={{ marginTop: 10, marginBottom: 5 }} />}

    </div>
  );
}

export default CardModuloComponente;
