import React from "react";
import { Form, Select, Switch } from "antd";
import Boton from "../../../../components/design/elementos/boton";
import { store } from "../../../../store";

const { Option } = Select;
var primerCarga = true;
function FormularioBloqueoPagina(props) {
  const [form] = Form.useForm();

  React.useEffect(() => {
    form.setFieldsValue(props.pestanaActual.bloqueo);
  }, [props.pestanaActual]);
  function bloquearPagina(values) {
    // replace "null" with null
    if( values.valor === "null" ){
      values.valor = null;
    }
    var data = [...props.data];
    var x = data.find((e) => e.id === props.pestanaActual.id);
    x.bloqueo = values;
    props.setData(data);
    props.actualizarMenu();
    props.cerrarModal();
  }
  return (
    <>
      <span>
        Puedes bloquear esta página para afiliados con las siguientes
        condicionales
      </span>

      <Form
        style={{ marginTop: 20 }}
        layout="vertical"
        onFinish={bloquearPagina}
        form={form}
      >
        <Form.Item label="Bloquear" name="bloqueada" valuePropName="checked">
          <Switch defaultChecked={form.getFieldsValue().bloqueada} />
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            currentValues.bloqueada !== prevValues.bloqueada
          }
        >
          {({ getFieldsValue }) => {
            return (
              getFieldsValue().bloqueada && (
                <Form.Item>
                  <Form.Item
                    rules={[{ required: true, message: "Campo obligatorio" }]}
                    defaultValue={getFieldsValue().atributo}
                    label="atributo"
                    name="atributo"
                  >
                    <Select showSearch={true}>
                      {store.losatributos.texts.map((res, index) => {
                        return (
                          <Option key={index} value={res.nombre}>
                            {res.nombre}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      currentValues.atributo !== prevValues.atributo
                    }
                  >
                    {({ getFieldsValue, setFieldsValue }) => {
                      var x = store.losatributos.texts.find(
                        (e) => e.nombre === getFieldsValue().atributo
                      );
                      if (x) {
                        return (
                          <>
                            <Form.Item
                              defaultValue="$eq"
                              name="operador"
                              rules={[
                                {
                                  required: true,
                                  message: "Campo obligatorio",
                                },
                              ]}
                            >
                              <Select placeholder="Es">
                                <Option value="$eq">Es Igual</Option>
                                <Option value="$ne">Es diferente</Option>
                              </Select>
                            </Form.Item>
                            <Form.Item
                              defaultValue={form.getFieldsValue().valor}
                              name="valor"
                              rules={[
                                {
                                  required: true,
                                  message: "Campo obligatorio",
                                },
                              ]}
                            >
                              <Select placeholder="a" showSearch={true}>
                                {x.opciones.map((res, index) => {
                                  return (
                                    <Option key={index} value={res}>
                                      {res}
                                    </Option>
                                  );
                                })}
                                <Option value="null">Indefinido</Option>
                              </Select>
                            </Form.Item>
                          </>
                        );
                      }
                    }}
                  </Form.Item>
                </Form.Item>
              )
            );
          }}
        </Form.Item>
        <Boton loading={store.pensando} texto="Guardar" htmlType="submit" />
      </Form>
    </>
  );
}

export default FormularioBloqueoPagina;
