import React from "react";
import { Divider, Form, Input, Button, Checkbox, Select } from "antd";
import Boton from "../../../design/elementos/boton";
import { setPensando, setStoreWidgets, store } from "../../../../store";
import { Api } from "../../../../api/configApi";
import { ObjectId } from "bson";
import CamposPersonalizados from "./CamposPersonalizados";

function CrearActualizarWidgetVideo(props) {
  const { Option } = Select;
  const [loading, setLoading] = React.useState(false);
  const [insertOrUpdate, setInsertOrUpdate] = React.useState("InsertWidgets");
  const [form] = Form.useForm();
  const guardarWidget = (values) => {
    values.tipo = "video";
    setPensando(true);
    setLoading(true);
    Api("Cuentas", insertOrUpdate, { _id: store.cuentaactiva }, values)
      .then((res) => {
        props.setVisible(false);
        setPensando(false);
        setLoading(false);
        return Api("Cuentas","Get",{ _id: store.cuentaactiva },{ widgets: 1 });
      }).then(res=>{
        setStoreWidgets(res[0].widgets);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setPensando(false);
        setLoading(false);
      });
  };
  React.useEffect(()=>{
    console.log(store.usuario);
    form.resetFields();
    form.setFieldsValue({ _id: new ObjectId(), dinamico: false });
  },[props.visible])
  React.useEffect(()=>{
    if( props.data ){
      form.setFieldsValue(props.data);
      setInsertOrUpdate("UpdateWidgets");
    }else{
      form.setFieldsValue({ _id: new ObjectId(), dinamico: false });
    }
  },[props.data]);
  return (
    <div>
      <Form form={form} onFinish={guardarWidget} layout="vertical">
        <Form.Item
          label="Nombre"
          name="nombre"
          hasFeedback
          rules={[{ required: true, message: "Ingresa un nombre" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="_id" hidden rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="dinamico" hidden rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item
          label="Titulo"
          name="titulo"
          hasFeedback
          rules={[{ required: true, message: "Ingresa un titulo" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Descripción"
          name="descripcion"
          hasFeedback
          rules={[{ required: true, message: "Ingresa una descripción" }]}
        >
          <Input />
        </Form.Item>

        {/* Campos personalizados de este WIDGET */}
        <CamposPersonalizados formulario={form} />
        {/* Fin campos personalizados de este Widget... */}

        <Form.Item noStyle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: 15,
            }}
          >
            <Button loading={loading} htmlType="submit">
              Guardar
            </Button>
            <Button
              loading={loading}
              onClick={() => {
                props.setVisible(false);
              }}
              htmlType="button"
            >
              Cancelar
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default CrearActualizarWidgetVideo;
