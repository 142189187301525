import React from "react";
import "antd/dist/antd.css";
// import Menu from "../components/Menu";
import Router from "../router";
import { useHistory, useLocation } from "react-router-dom";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

import firebase from "firebase";

import {
  store,
  setCuentaActiva,
  setMostrarmenu,
  setNombrecuenta,
  setStoreFlujos,
  setStoreAtributos,
  setStoreWidgets,
  setStoreMenuFront,
  setStoreMenuAdmin,
  setPensando,
  setStoreLogo,
  setStoreUsuario,
  setStoreVersion,
  setStoreAfiliado,
  setStoreCuenta,
  setStoreRed,
  setStoreNotificaciones,
  setStoreEmails,
  setStoreZonaHoraria,
  setStoreFuente,
  setStoreColores,
  setStoreMenuApp,
  setStoreListados,
  setStoreCargando,
  setStoreActualizarLaEstructura,
  setStoreLicenciaActiva,
} from "../store";
import { observer } from "mobx-react";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Api } from "../api/configApi";

import {
  Layout,
  Menu,
  Breadcrumb,
  message,
  Drawer,
  Badge,
  notification,
  Image,
} from "antd";

import { Notifications, SettingsOutlined } from "@material-ui/icons";
import hexRgb from "hex-rgb";
import PerfilAfiliado from "./perfilAfiliado/perfilAfiliado";
import ListadeNotificaciones from "../components/notificaciones/listadeNotificaciones";
import Pulpitos from "../components/pulpitos/pulpitos";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faUser } from "@fortawesome/free-solid-svg-icons";
import { faEllo } from "@fortawesome/free-brands-svg-icons";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

function Estructura(props) {
  const [perfil, setPerfil] = React.useState(false);
  const [menuWidth, setMenuWidth] = React.useState(80);
  const [menuCollapsado, setMenuCollapsado] = React.useState(false);
  const [notificaciones, setNotificaciones] = React.useState(false);
  const [notificacionesPendientes, setNotificacionespendientes] =
    React.useState(false);
  const [colorTextoHeader, setColorTextHeader] = React.useState();
  const [drawerPulpitos, setDrawerPulpitos] = React.useState();

  let history = useHistory();
  const location = useLocation();

  const createElement = (url) => {
    const element = document.createElement("link");
    element.rel = "stylesheet";
    element.href = url;
    return element;
  };

  function ActualizarFuente(lin) {
    const neuva = createElement(lin);
    document.head.appendChild(neuva);

    document.documentElement.style.setProperty("--fuente-actual", store.fuente);

    return () => document.head.removeChild(neuva);
  }

  React.useEffect(() => {
    ActualizarFuente(
      `https://fonts.googleapis.com/css2?family=${store.fuente}:ital,wght@0,100;0,200;0,300;0,700;1,100;1,200&display=swap`
    );
  }, [store.fuente]);

  React.useEffect(() => {
    // verificar y/o loguear en firebase
    if (isMobile) {
      setMenuWidth(0);
      setMenuCollapsado(true);
    }
    window.document.title = window.location.host;
    var rgb = hexRgb(store.colores.header[0]);
    var calucl = rgb.red + rgb.green + rgb.blue;
    if (calucl <= 400) {
      setColorTextHeader("dark");
      document.documentElement.style.setProperty(
        "--color-iconos-header",
        "#fff"
      );
    } else {
      setColorTextHeader("light");
      document.documentElement.style.setProperty(
        "--color-iconos-header",
        "#000"
      );
    }
    var rgb2 = hexRgb(store.colores.barralateral[0]);
    var calucl2 = rgb2.red + rgb2.green + rgb2.blue;
    if (calucl2 <= 400) {
      document.documentElement.style.setProperty(
        "--color-texto-barra-lateral",
        "#fff"
      );
      document.documentElement.style.setProperty(
        "--fondo-blanco-menu-lateral",
        1
      );
    } else {
      document.documentElement.style.setProperty(
        "--color-texto-barra-lateral",
        "#000"
      );
      document.documentElement.style.setProperty(
        "--fondo-blanco-menu-lateral",
        0
      );
    }
    setTimeout(() => {
      var fda = document.getElementsByClassName("ant-layout-sider-trigger");
      fda.length > 0 &&
        (fda[0].style.backgroundColor = store.colores.header[0]);

      document.documentElement.style.setProperty(
        "--colorhover",
        store.colores.primarios[0]
      );
      document.documentElement.style.setProperty(
        "--submenu-activo",
        "4px solid " + store.colores.primarios[0]
      );
      document.documentElement.style.setProperty(
        "--border-menu-principal",
        "3px solid " + store.colores.primarios[0]
      );
      document.documentElement.style.setProperty(
        "--color-principal",
        store.colores.primarios[0]
      );
    }, 300);
  }, [store.colores]);

  React.useEffect(() => {
    const auth = firebase.auth();
    const token = localStorage.getItem("token");

    if (!auth.currentUser) {
      auth
        .signInAnonymously()
        .then(async () => {
          await firebase.functions().httpsCallable("actualizarHash")({
            token: token,
          });
          auth.currentUser.getIdToken(true);
        })
        .catch((error) => {});
    } else {
      firebase.functions().httpsCallable("actualizarHash")({
        token: token,
      });
      auth.currentUser.getIdToken(true);
    }
    //
    Api("usuarios", "Get").then((res) => {
      setStoreUsuario(res || {});
      setCuentaActiva(res.cuentaactiva);

      if (res.cuentaactiva) {
        Api("cuentas", "Get", { _id: res.cuentaactiva }).then(async (resp) => {
          setStoreLicenciaActiva(resp[0]?.licenciaActiva);
          setStoreCuenta(resp[0] || "");
          setNombrecuenta(resp[0].nombre || "");
          setStoreFlujos(resp[0].flujos || []);
          setStoreAtributos(resp[0].atributos || []);
          setStoreRed(resp[0].red || []);
          setStoreNotificaciones(resp[0].notificaciones || []);
          setStoreEmails(resp[0].emails || []);

          setStoreZonaHoraria(resp[0].zonaHoraria || []);
          setStoreFuente(resp[0]?.fuente || "Poppins");
          setStoreColores(resp[0]?.colores || {});

          setStoreListados(resp[0].listados || []);
          console.log("Hecho listados..");
          setStoreLogo(resp[0].logo);
          setStoreVersion(resp[0]._version || 1.0);

          // verificamos que widgets tiene autorizado ver el usuario
          const widgetsAutorizados = [];
          var siTiendeCondicionales = false;
          var widgetsProcesados = 0;

          const resolverAtributos = async () => {
            return new Promise((resolve, reject) => {
              resp[0].widgets.length === 0 && resolve();
              resp[0]?.widgets?.forEach((widget, index) => {
                widgetsProcesados++;
                // aqui almaceno los atributos ya con sus valores de la DB
                const atributosCondicionales = [];
                const condicionales = widget.condicionales || [];

                // si no tiene condicionales lo agrego a los widgets autorizados
                if (condicionales.length === 0) {
                  widgetsAutorizados.push(widget);
                  // si es e ultimo widget y ningun otro tiene condicionales termino el proceso
                  if (widgetsProcesados === resp[0].widgets.length) {
                    if (!siTiendeCondicionales) {
                      store.usuario.tipo === "afiliado"
                        ? setStoreWidgets(widgetsAutorizados || [])
                        : setStoreWidgets(resp[0].widgets || []);
                      resolve();
                    }
                  } else {
                    return;
                  }
                }

                siTiendeCondicionales = true;
                // aqui almaceno los atributos que son usados para condicionales aún no tienen valor.
                const atributos = [];

                condicionales.forEach((condicional) => {
                  const tengoResultado = atributosCondicionales.find(
                    (atributo) => atributo.nombre === condicional.nombre
                  );
                  if (!tengoResultado) {
                    atributos.push({
                      atributo: condicional.atributo,
                      tipo: [
                        ...resp[0].atributos.dates,
                        ...resp[0].atributos.numbers,
                        ...resp[0].atributos.texts,
                      ].find((atr) => atr.nombre === condicional.atributo)
                        ?.tipo,
                    });
                  }
                });

                const atribTexto = atributos.filter(
                  (atr) => atr.tipo === "text"
                );

                // aqui termino el proceso si no exsite ningun atributo de texto y es el ultimo widget a procesar
                if (
                  atribTexto.length === 0 &&
                  widgetsProcesados === resp[0].widgets.length
                ) {
                  store.usuario.tipo === "afiliado"
                    ? setStoreWidgets(widgetsAutorizados || [])
                    : setStoreWidgets(resp[0].widgets || []);
                  resolve();
                  return;
                }

                const ProcesarCondicionales = async () => {
                  var cumpleCondicionales = true;
                  var cantidadCondicionalesVerificadas = 0;
                  widget.condicionales.forEach(async (condicional) => {
                    const valorCondicional = condicional.valor;

                    const valorUsuario = atributosCondicionales.find(
                      (e) => e.atributo === condicional.atributo
                    )?.valor;
                    cantidadCondicionalesVerificadas++;
                    if (
                      valorCondicional === valorUsuario &&
                      condicional.operador === "$eq" &&
                      cumpleCondicionales
                    ) {
                      cantidadCondicionalesVerificadas ===
                        widget.condicionales.length &&
                        widgetsAutorizados.push(widget);
                    } else if (
                      valorCondicional !== valorUsuario &&
                      condicional.operador === "$ne" &&
                      cumpleCondicionales
                    ) {
                      widget.condicionales.length &&
                        widgetsAutorizados.push(widget);
                    } else {
                      cumpleCondicionales = false;
                    }
                  });

                  function eliminarWidgetDelMenu(menu) {
                    menu.widgets.forEach((fila) => {
                      fila.forEach((wid) => {
                        if (wid._id !== widget._id) return;
                        var existe = widgetsAutorizados.find(
                          (e) => e._id === wid._id
                        );

                        if (!existe) {
                          // eliminamos el widget del menu
                          fila.splice(fila.indexOf(wid), 1);
                        }
                      });
                    });
                  }
                  resp[0].menuFront.forEach((menu) => {
                    eliminarWidgetDelMenu(menu);
                    if (menu.children.length > 0) {
                      menu.children.forEach((m) => {
                        eliminarWidgetDelMenu(m);
                      });
                    }
                  });

                  if (widgetsProcesados === resp[0].widgets.length) {
                    // solo para darle un chance a que se eliminen los widgets
                    store.usuario.tipo === "afiliado"
                      ? setStoreWidgets(widgetsAutorizados || [])
                      : setStoreWidgets(resp[0].widgets || []);

                    resolve();
                  }
                };
                // aqui si existen atributos de texto llamo a la api para obtener sus valores
                if (atribTexto.length > 0) {
                  Api("atributosTexto", "GetGrupoAtributos", atribTexto).then(
                    (res) => {
                      // aqui almaceno los valores de los atributos de texto

                      //agregar a atributosCondicionales los objetos del array res.atributos
                      res.atributos.forEach((atributo) => {
                        atributosCondicionales.push(atributo);
                      });

                      // aqui llamo a la funcion que procesa las condicionales
                      ProcesarCondicionales();
                    }
                  );
                }
              });
            });
          };

          await resolverAtributos();

          // verificar si es admin y bloqueamos las pestañas no autorizadas.

          if (resp[0].menuFront && store.usuario.tipo === "afiliado") {
            var menuAfiliado = resp[0].menuFront;
            var x = 0;
            if (menuAfiliado.filter((e) => e.bloqueo.bloqueada).length === 0) {
              setStoreMenuFront(menuAfiliado || []);
            } else {
              menuAfiliado.forEach((element) => {
                if (element.bloqueo.bloqueada) {
                  x++;
                  Api("atributosTexto", "GetAtributo", {
                    idCuenta: store.cuentaactiva,
                    atributo: element.bloqueo.atributo,
                  })
                    .then((res) => {
                      x--;
                      if (res.success) {
                        if (
                          (res.valor === element.bloqueo.valor &&
                            element.bloqueo.operador === "$eq") ||
                          (res.valor !== element.bloqueo.valor &&
                            element.bloqueo.operador === "$ne")
                        ) {
                          const s = menuAfiliado.findIndex(
                            (e) => e.title === element.title
                          );
                          menuAfiliado.splice(s, 1);
                        }
                      }
                      if (x === 0) {
                        setStoreMenuFront(menuAfiliado || []);
                      }
                    })
                    .catch((er) => {
                      setStoreMenuFront([]);
                      message.error(
                        "No pudimos cargar el menú actualiza la página."
                      );
                    });
                }
              });
            }
          } else {
            setStoreMenuFront(resp[0].menuFront || []);
            setStoreMenuApp(resp[0].menuApp || []);
          }

          setStoreMenuAdmin(resp[0].menuAdmin || []);
          setMostrarmenu(true);
          setStoreCargando(false);
        });
      } else {
        setMostrarmenu(true);
        setStoreCargando(false);
        history.push("/cuentas");
      }
    });
    Api("afiliados", "Get").then((afil) => {
      setStoreAfiliado(afil || {});
    });
    Api("notificaciones", "GetNoLeida").then((res) => {
      if (res.success) {
        var actualizadas = 0;
        res.notificaciones.forEach((element) => {
          if (element.burbuja) {
            actualizadas++;
            notification["open"]({
              message: element.titulo,
              description: element.contenido,
            });
            Api("notificaciones", "UpdateViewOne", { _id: element._id });
          }
        });
        var totalpendientes = res.notificaciones.length - actualizadas;
        if (totalpendientes > 0) {
          setNotificacionespendientes(true);
        } else {
          setNotificacionespendientes(false);
        }
      }
    });
  }, [history, store.actualizarLaEstructura]);

  React.useEffect(() => {
    if (store.cuenta._id) {
      const idCuenta = store.cuenta._id + "/" + store.cuenta._id;
      var starCountRef = firebase.database().ref(idCuenta);
      var primeraCarga = true;
      var esperar = false;
      starCountRef.on("value", (snapshot) => {
        if (!primeraCarga) {
          setStoreActualizarLaEstructura();
          notification.warning({
            placement: "bottomRight",
            message: "Actualizando Software",
            description: "Un administrador hizo una actualización",
          });
          if (!esperar) {
            esperar = true;
            setTimeout(() => {
              esperar = false;
            }, 5000);
          }
        }
        primeraCarga = false;
      });

      return () => {
        starCountRef.off();
      };
    }
  }, [store.cuenta._id]);

  return (
    <>
      <Drawer
        title="Perfil"
        placement="right"
        onClose={() => setPerfil(false)}
        closable={false}
        visible={perfil}
        width={400}
      >
        <PerfilAfiliado setPerfil={setPerfil} />
      </Drawer>
      <Drawer
        title="Pulpitos"
        placement="right"
        onClose={() => setDrawerPulpitos(false)}
        closable={false}
        visible={drawerPulpitos}
        width={400}
      >
        <Pulpitos setDrawerPulpitos={setDrawerPulpitos} />
      </Drawer>
      <Drawer
        title="Notificaciones"
        placement="right"
        onClose={() => setNotificaciones(false)}
        closable={false}
        visible={notificaciones}
        width={400}
      >
        <ListadeNotificaciones
          setNotificacionespendientes={setNotificacionespendientes}
          setDrawer={setNotificaciones}
        />
      </Drawer>
      {!store.cargando && (
        <>
          {store.pensando && (
            <LinearProgress
              style={{
                color: "rgb(11, 134, 183)",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 10000,
              }}
            />
          )}

          <Layout>
            <Sider
              collapsible
              collapsedWidth={menuWidth}
              defaultCollapsed={menuCollapsado}
              width={200}
              className="site-layout-background b-green"
              theme={colorTextoHeader}
            >
              <Menu
                mode="inline"
                defaultSelectedKeys={[location.pathname.split("/")[2]]}
                defaultOpenKeys={[location.pathname.split("/")[2]]}
                style={{
                  height: "100%",
                  borderRight: 0,
                  borderLeft: 0,
                  background: store.colores.barralateral[0],
                }}
                className="menuIzquierdaPadre"
              >
                <div style={{ display: "flex" }}>
                  {store.logo && (
                    <img
                      onClick={() => history.push("/")}
                      style={{
                        padding: 10,
                        margin: "auto",
                        maxHeight: 120,
                        marginBottom: 15,
                        maxWidth: "100%",
                        cursor: "pointer",
                      }}
                      src={store.logo}
                      alt="logo"
                    />
                  )}
                </div>
                {store.usuario.tipo === "afiliado"
                  ? store.menuFront
                      .filter((e) => {
                        return e.activa;
                      })
                      .map((e, index) => {
                        return e.children.length === 0 ? (
                          <Menu.Item
                            style={{ color: "#000" }}
                            key={e.id}
                            className="menuIzquierda"
                            onClick={(a) => {
                              history.push(`/page/${e.id}`);
                            }}
                            icon={
                              <img
                                alt="icono"
                                width={20}
                                src={`https://octopusmultinivel.b-cdn.net/octopus10/ICONOS/flat/${e.icono}`}
                              />
                            }
                          >
                            {e.title}
                          </Menu.Item>
                        ) : (
                          <SubMenu
                            style={{ color: "#000" }}
                            className="menuIzquierda"
                            icon={
                              <img
                                alt="icono"
                                width={20}
                                src={`https://octopusmultinivel.b-cdn.net/octopus10/ICONOS/flat/${e.icono}`}
                              />
                            }
                            key={"submenu" + e.id}
                            title={e.title}
                          >
                            {e.children.map((e) => {
                              return (
                                <Menu.Item
                                  style={{ color: "#000" }}
                                  key={e.id}
                                  className="menuIzquierda"
                                  onClick={(a) => {
                                    history.push(`/page/${e.id}`);
                                  }}
                                  icon={
                                    <img
                                      alt="icono"
                                      width={20}
                                      src={`https://octopusmultinivel.b-cdn.net/octopus10/ICONOS/flat/${e.icono}`}
                                    />
                                  }
                                >
                                  {e.title}
                                </Menu.Item>
                              );
                            })}
                          </SubMenu>
                        );
                      })
                  : store.menuAdmin
                      .filter((e) => {
                        return e.activa;
                      })
                      .map((e, index) => {
                        return e.children.length === 0 ? (
                          <Menu.Item
                            style={{ color: "#000" }}
                            key={e.id}
                            className="menuIzquierda"
                            onClick={(a) => {
                              history.push(`/page/${e.id}`);
                            }}
                            icon={
                              <img
                                alt="icono"
                                width={20}
                                src={`https://octopusmultinivel.b-cdn.net/octopus10/ICONOS/flat/${e.icono}`}
                              />
                            }
                          >
                            {e.title}
                          </Menu.Item>
                        ) : (
                          <SubMenu
                            icon={
                              <img
                                alt="icono"
                                width={20}
                                src={`https://octopusmultinivel.b-cdn.net/octopus10/ICONOS/flat/${e.icono}`}
                              />
                            }
                            key={"submenu" + e.id}
                            className="menuIzquierda"
                            title={e.title}
                          >
                            {e.children.map((e) => {
                              return (
                                <Menu.Item
                                  style={{ color: "#000" }}
                                  key={e.id}
                                  className="menuIzquierda"
                                  onClick={(a) => {
                                    history.push(`/page/${e.id}`);
                                  }}
                                  icon={
                                    <img
                                      alt="icono"
                                      width={20}
                                      src={`https://octopusmultinivel.b-cdn.net/octopus10/ICONOS/flat/${e.icono}`}
                                    />
                                  }
                                >
                                  {e.title}
                                </Menu.Item>
                              );
                            })}
                          </SubMenu>
                        );
                      })}
              </Menu>
              {!store.licenciaActiva && (
                <div
                  style={{
                    background: "red",
                    width: "100%",
                    textAlign: "center",
                    color: "#fff",
                    height: 30,
                    zIndex: 100000000,
                    fontSize: 15,
                    position: "absolute",
                    bottom: 50,
                  }}
                >
                  <span>Licencia no activada.</span>
                </div>
              )}
            </Sider>

            <Layout>
              <Header
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  background: store.colores.header[0],
                }}
                className="header"
              >
                <span
                  style={{ fontSize: 13, marginRight: 7 }}
                  className="textoFondoOscuro"
                >
                  {store.user.nombre}
                </span>

                <div
                  style={{ border: "none", display: "flex", cursor: "pointer" }}
                  className="menuHeader"
                  theme="light"
                  onClick={() => {
                    setNotificaciones(true);
                  }}
                >
                  <Badge className="menuHeader" dot={notificacionesPendientes}>
                    <Notifications />
                  </Badge>
                </div>

                <Menu
                  triggerSubMenuAction="click"
                  mode="horizontal"
                  style={{ background: "none", border: "none" }}
                  theme="light"
                >
                  <SubMenu
                    key="confja"
                    icon={
                      <SettingsOutlined style={{ fontSize: 20, margin: 5 }} />
                    }
                    style={{
                      border: "none",
                      alignItems: "center",
                      display: "flex",
                    }}
                    className="menuHeader"
                    theme="light"
                  >
                    {store.usuario.tipo === "afiliado" ? (
                      <>
                        <Menu.Item onClick={() => setPerfil(true)} key="perfil">
                          Perfil
                        </Menu.Item>
                        <Menu.Item
                          onClick={() => {
                            history.push("/soporte");
                          }}
                          key="soporte"
                        >
                          Soporte
                        </Menu.Item>
                        <Menu.Item
                          onClick={() => {
                            setPensando(true);
                            localStorage.removeItem("token");
                            window.location.reload();
                          }}
                          key="setting:5"
                          style={{ color: "red" }}
                        >
                          Salir
                        </Menu.Item>
                      </>
                    ) : (
                      <>
                        <Menu.Item
                          onClick={() => {
                            history.push(
                              "/configuraciones/diseno/identidadvisual"
                            );
                          }}
                          key="setting:1"
                        >
                          Diseño
                        </Menu.Item>
                        <Menu.Item
                          onClick={() => {
                            history.push("/soportecontrol");
                          }}
                          key="soporte"
                        >
                          Soporte
                        </Menu.Item>
                        <Menu.Item
                          onClick={() => {
                            history.push("/configuraciones/index/flujos");
                          }}
                          key="setting:2"
                        >
                          Lógica
                        </Menu.Item>
                        <Menu.Item
                          onClick={() => {
                            history.push("/modulos");
                          }}
                          key="setting:3"
                        >
                          Modulos
                        </Menu.Item>
                        <Menu.Item
                          onClick={() => {
                            history.push("/cuenta/plan");
                          }}
                          key="setting:4"
                        >
                          Cuenta
                        </Menu.Item>
                        {/* <Menu.Item
                          onClick={() => {
                            setDrawerPulpitos(true);
                          }}
                          key="setting:10"
                        >
                          Pulpitos
                        </Menu.Item> */}
                        <Menu.Item
                          onClick={() => {
                            setPensando(true);
                            localStorage.removeItem("token");
                            window.location.reload();
                          }}
                          key="setting:5"
                          style={{ color: "red" }}
                        >
                          Salir
                        </Menu.Item>
                      </>
                    )}
                  </SubMenu>
                </Menu>
              </Header>
              <Layout style={{ padding: "0 24px 24px", overflow: "auto" }}>
                <Breadcrumb style={{ margin: "16px 0" }}>
                  {store.cuenta.admin === store.usuario._id && (
                    <>
                      <Breadcrumb.Item
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          history.push("/configuraciones/index/flujos");
                        }}
                      >
                        Flujos
                      </Breadcrumb.Item>
                      <Breadcrumb.Item
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          history.push("/configuraciones/index/atributos");
                        }}
                      >
                        Atributos
                      </Breadcrumb.Item>
                      <Breadcrumb.Item
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          history.push("/configuraciones/diseno/widgets");
                        }}
                      >
                        Widgets
                      </Breadcrumb.Item>
                      <Breadcrumb.Item
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          history.push("/configuraciones/index/listados");
                        }}
                      >
                        Listados
                      </Breadcrumb.Item>
                      <Breadcrumb.Item> </Breadcrumb.Item>
                    </>
                  )}
                </Breadcrumb>
                <Content
                  className="site-layout-background"
                  style={{
                    padding: 24,
                    margin: 0,
                    height: "auto",
                    minHeight: "auto",
                  }}
                >
                  <Router />
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </>
      )}
    </>
  );
}

export default observer(Estructura);
