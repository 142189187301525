import React from "react";
import { Divider, Form, Input, Button, Checkbox, Select, message } from "antd";
import Boton from "../../../design/elementos/boton";
import { setPensando, setStoreWidgets, store } from "../../../../store";
import { Api } from "../../../../api/configApi";
import { ObjectId } from "bson";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";

function CrearActualizarWidgetLogros(props) {
  const { Option } = Select;
  const [loading, setLoading] = React.useState(false);
  const [insertOrUpdate, setInsertOrUpdate] = React.useState("InsertWidgets");
  const [form] = Form.useForm();
  const guardarWidget = (values) => {
    values.tipo = "logros";
    setPensando(true);
    setLoading(true);
    values.limiteCantidad = +values.limiteCantidad;
    values.limiteWidth = +values.limiteWidth;
    Api("Cuentas", insertOrUpdate, { _id: store.cuentaactiva }, values)
      .then((res) => {
        props.setVisible(false);
        setPensando(false);
        setLoading(false);
        return Api(
          "Cuentas",
          "Get",
          { _id: store.cuentaactiva },
          { widgets: 1 }
        );
      })
      .then((res) => {
        setStoreWidgets(res[0].widgets);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setPensando(false);
        setLoading(false);
      });
  };
  React.useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ _id: new ObjectId(), dinamico: false });
  }, [props.visible]);
  React.useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
      setInsertOrUpdate("UpdateWidgets");
    } else {
      form.setFieldsValue({ _id: new ObjectId(), dinamico: false });
    }
  }, [props.data]);
  return (
    <div>
      <Form form={form} onFinish={guardarWidget} layout="vertical">
        <Form.Item
          label="Nombre"
          name="nombre"
          hasFeedback
          rules={[{ required: true, message: "Ingresa un nombre" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="_id" hidden rules={[{ required: true }]}></Form.Item>
        <Form.Item
          name="dinamico"
          hidden
          rules={[{ required: true }]}
        ></Form.Item>

        <Form.Item
          label="Titulo"
          name="titulo"
          hasFeedback
          rules={[{ required: true, message: "Ingresa un titulo" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Descripción"
          name="descripcion"
          hasFeedback
          rules={[{ required: true, message: "Ingresa una descripción" }]}
        >
          <Input />
        </Form.Item>
        {/* select */}
        <Form.Item
          label="Cantidad"
          name="limiteCantidad"
          hasFeedback
          rules={[
            { required: true, message: "Ingresa una cantidad de elementos" },
          ]}
        >
          <Input type="number" max={50} min={2} />
        </Form.Item>
        <Form.Item
          label="Ancho Máximo (px) recomendado: 50px"
          name="limiteWidth"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Ancho máximo en pixeles para cada imagen",
            },
          ]}
        >
          <Input type="number" min={50} />
        </Form.Item>
        <Form.Item
          label="Imagen"
          name="imagen"
          hasFeedback
          rules={[{ required: true, message: "Ingresa la url de una imagen." }]}
        >
          <Input type="url" />
        </Form.Item>
        <Form.Item
          name="fondo"
          label="Color de fondo"
          rules={[{ required: true }]}
        >
          <Select placeholder="Selecciona un color" allowClear>
            <Option value="#fff">Blanco</Option>
            <Option value="#1389422b">Verde</Option>
            <Option value="#8913132b">Rojo</Option>
            <Option value="#8789132b">Amarillo</Option>
            <Option value="#1358892b">Azul</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Atributo numero"
          name="id_atributo"
          rules={[
            {
              required: true,
              message: "Seleccionar un atributo",
            },
          ]}
          hasFeedback
        >
          <Select placeholder="Seleccionar un atributo o una operación">
            {store.losatributos.numbers.map((atri) => {
              return (
                <Option key={atri._id} value={atri._id}>
                  {atri.nombre}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.List name="condicionales">
          {(fields, { add, remove }) => (
            <div
              style={{
                background: "rgb(237 237 237)",
                minHeight: 100,
                padding: 10,
                borderRadius: 5,
              }}
            >
              {fields.map(({ key, name, fieldKey, ...restField }, index2) => (
                <div
                  key={key}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Form.Item
                    style={{ flex: 1, margin: 5 }}
                    hasFeedback
                    {...restField}
                    colon={false}
                    name={[name, "atributo"]}
                    fieldKey={[fieldKey, "atributo"]}
                    rules={[
                      {
                        required: true,
                        message: "Campo obligatorio",
                      },
                    ]}
                    label="Atributo"
                  >
                    <Select>
                      {store.losatributos.texts.map((atr) => {
                        return <Option value={atr.nombre}>{atr.nombre}</Option>;
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    style={{ flex: 1, margin: 5 }}
                    hasFeedback
                    {...restField}
                    colon={false}
                    name={[name, "operador"]}
                    fieldKey={[fieldKey, "operador"]}
                    rules={[
                      {
                        required: true,
                        message: "Campo obligatorio",
                      },
                    ]}
                    label="operador"
                  >
                    <Select>
                      <Option value="$eq">Es Igual</Option>
                      <Option value="$ne">Es diferente</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item style={{ flex: 1, margin: 5 }} shouldUpdate>
                    {({ getFieldValue }) => {
                      return (
                        getFieldValue().condicionales[index2] && (
                          <Form.Item
                            {...restField}
                            name={[name, "valor"]}
                            fieldKey={[fieldKey, "valor"]}
                            label="Valor"
                            style={{
                              flex: 1,
                            }}
                            rules={[
                              {
                                required: true,
                                message: "Campo obligatorio",
                              },
                            ]}
                          >
                            <Select>
                              {store.losatributos.texts
                                .find((at) => {
                                  return (
                                    at.nombre ===
                                    getFieldValue().condicionales[index2]
                                      .atributo
                                  );
                                })
                                .opciones.map((op, index) => {
                                  return (
                                    <Option key={"i" + index} value={op}>
                                      {op}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </Form.Item>
                        )
                      );
                    }}
                  </Form.Item>
                  <FontAwesomeIcon
                    style={{
                      cursor: "pointer",
                      alignSelf: "center",
                      marginTop: 27,
                      marginLeft: 10,
                    }}
                    icon={faMinusCircle}
                    onClick={() => remove(name)}
                  />
                </div>
              ))}

              <p
                style={{
                  fontSize: 13,
                  marginBottom: 30,
                  fontWeight: 600,
                  cursor: "pointer",
                  marginTop: 10,
                  textAlign: "center",
                }}
                onClick={() => {
                  store.losatributos.texts.length > 0
                    ? add()
                    : message.error(
                        "Debe tener atributos TEXTO para crear condicionales"
                      );
                }}
              >
                + Agregar Condicional
              </p>
            </div>
          )}
        </Form.List>
        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: 15,
            }}
          >
            <Boton loading={loading} texto="Guardar" htmlType="submit" />
            <Boton
              loading={loading}
              onClick={() => {
                props.setVisible(false);
              }}
              texto="Cancelar"
              tipo="danger"
              htmlType="button"
            />
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default CrearActualizarWidgetLogros;
