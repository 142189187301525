import React from "react";
import FiltrosDisparadores from "../embudos/filtrosDisparadores/filtrosDisparadores";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import Accionescontroller from "../embudos/accionesController";
import { Switch } from "@material-ui/core";
import FlujosJSONDISPARADORES from "../../api/flujos/configFlujosDisparadores.json";
import FlujosJSONACCIONES from "../../api/flujos/configFlujosAcciones.json";
import FlujosJSONQUIENES from "../../api/flujos/configFlujosQuienes.json";
import FiltrosQuienes from "../embudos/filtrosQuienes/filtrosQuienes";

function FlujoIndividual(props) {
  const [disparadores, setDisparadores] = React.useState([]);
  const [disparadoractual, setDisparadoractual] = React.useState("1");
  const [quienes, setQuienes] = React.useState([]);
  const [quienesactual, setQuienesactual] = React.useState("1");
  const [acciones, setAcciones] = React.useState([]);
  const [accionactual, setAccionactual] = React.useState("1");
  const [actualizarlista, setActualizarlista] = React.useState(false);
  const [configFlujo, setConfigFlujo] = React.useState({
    disparador: null,
    filtrodisparador: {
      incluidos: [],
      excluidos: [],
    },
    quienes: null,
    filtroquienes: {
      incluidos: [],
      excluidos: [],
    },
    accion: null,
    configaccion: null,
    publicado: false,
  });

  React.useEffect(() => {
    setDisparadores(FlujosJSONDISPARADORES.disparadores);
  }, []);

  const agregarFiltro = (e, incluidos, tipo) => {
    const constConfigFlujo = { ...configFlujo };
    if (tipo === "disparadores") {
      console.log(e);
      if (incluidos) {
        constConfigFlujo.filtrodisparador.incluidos.push(e);
      } else {
        constConfigFlujo.filtrodisparador.excluidos.push(e);
      }
    }
    if (tipo === "quienes") {
      console.log(e);
      if (incluidos) {
        constConfigFlujo.filtroquienes.incluidos.push(e);
      } else {
        constConfigFlujo.filtroquienes.excluidos.push(e);
      }
    }

    setConfigFlujo(constConfigFlujo);
  };

  const eliminarfiltro = (id, incluidos, tipo) => {
    const constConfigFlujo = { ...configFlujo };
    if( tipo === "disparadores" ){
      if (incluidos) {
        constConfigFlujo.filtrodisparador.incluidos.forEach((filtro, index) => {
          if (filtro.id === id) {
            constConfigFlujo.filtrodisparador.incluidos.splice(index, 1);
          }
        });
      } else {
        constConfigFlujo.filtrodisparador.excluidos.forEach((filtro, index) => {
          if (filtro.id === id) {
            constConfigFlujo.filtrodisparador.excluidos.splice(index, 1);
          }
        });
      }
    }
    if( tipo === "quienes" ){
      if (incluidos) {
        constConfigFlujo.filtroquienes.incluidos.forEach((filtro, index) => {
          if (filtro.id === id) {
            constConfigFlujo.filtroquienes.incluidos.splice(index, 1);
          }
        });
      } else {
        constConfigFlujo.filtroquienes.excluidos.forEach((filtro, index) => {
          if (filtro.id === id) {
            constConfigFlujo.filtroquienes.excluidos.splice(index, 1);
          }
        });
      }
    }
    setActualizarlista(!actualizarlista);
  };

  const actualizarquienes = (e) => {
    setQuienes(
      FlujosJSONQUIENES.quienes.filter((quien) => {
        return quien.disparadores.includes(e);
      })
    );
    setDisparadoractual(e);
    configFlujo.disparador = e;
    configFlujo.quienes = null;
    configFlujo.accion = null;
    setQuienesactual("1");
    setAccionactual("1");
  };
  const actualizarAcciones = (e) => {
    setAcciones(
      FlujosJSONACCIONES.acciones.filter((accion) => {
        return accion.quienes.includes(e);
      })
    );
    setQuienesactual(e);
    configFlujo.quienes = e;
  };
  return (
    <div
      style={{
        maxWidth: "100%",
        marginTop: 10,
        padding: 25,
        border: "1px solid rgb(219, 223, 234)",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        position: "relative",
        marginBottom: 30,
      }}
    >
      <div className="tituloflujo">
        <span>Pagar Bono de equipo</span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
          position: "relative",
        }}
      >
        <span style={{ fontWeight: "bold", fontSize: 12, marginBottom: 5 }}>
          Disparador
        </span>
        <select
          value={disparadoractual}
          onChange={(e) => {
            actualizarquienes(e.target.value);
            setDisparadoractual(e.target.value);
          }}
          className="seleccionar"
        >
          <option disabled value="1">
            Agrega un Disparador
          </option>
          {disparadores.map((disparador) => {
            return (
              <option key={disparador.id} value={disparador.id}>
                {disparador.nombre}
              </option>
            );
          })}
        </select>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          position: "relative",
          paddingTop: 14,
        }}
      >
        <svg height="1" width="50">
          <line x1="0" y1="0" x2="50" y2="0" className="linea" />
        </svg>

        <FiltrosDisparadores
          actualizarlista={actualizarlista}
          eliminarfiltro={eliminarfiltro}
          listadefiltros={configFlujo.filtrodisparador}
          agregarFiltro={agregarFiltro}
          disparadoractual={disparadoractual}
        />
        <FontAwesomeIcon
          style={{
            fontSize: 20,
            position: "absolute",
            color: "#000",
            right: 0,
            alignSelf: "center",
          }}
          icon={faCaretRight}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
        }}
      >
        <span style={{ fontWeight: "bold", fontSize: 12, marginBottom: 5 }}>
          Quien(es)
        </span>
        <select
          value={quienesactual}
          onChange={(e) => {
            actualizarAcciones(e.target.value);
            setQuienesactual(e.target.value);
          }}
          className="seleccionar"
        >
          <option disabled value="1">
            Agrega un Quien(es)
          </option>
          {quienes.map((quien) => {
            return (
              <option key={quien.id} value={quien.id}>
                {quien.nombre}
              </option>
            );
          })}
        </select>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
          justifyContent: "center",
          position: "relative",
          paddingTop: 14,
        }}
      >
        <svg height="1" width="50">
          <line x1="0" y1="0" x2="50" y2="0" className="linea" />
        </svg>
        <FiltrosQuienes
          actualizarlista={actualizarlista}
          eliminarfiltro={eliminarfiltro}
          listadefiltros={configFlujo.filtroquienes}
          agregarFiltro={agregarFiltro}
          quienesactual={quienesactual}
        />
        <FontAwesomeIcon
          style={{
            fontSize: 20,
            position: "absolute",
            color: "#000",
            right: 0,
            alignSelf: "center",
          }}
          icon={faCaretRight}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
        }}
      >
        <span style={{ fontWeight: "bold", fontSize: 12, marginBottom: 5 }}>
          Acción
        </span>
        <select
          value={accionactual}
          onChange={(e) => {
            setAccionactual(e);
            setAccionactual(e.target.value);
          }}
          className="seleccionar"
        >
          <option disabled value="1">
            Agrega una acción
          </option>
          {acciones.map((accion) => {
            return (
              <option key={accion.id} value={accion.id}>
                {accion.nombre}
              </option>
            );
          })}
        </select>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
          justifyContent: "center",
          position: "relative",
          paddingTop: 14,
        }}
      >
        <svg height="1" width="70">
          <line x1="0" y1="0" x2="70" y2="0" className="linea" />
        </svg>
        <Accionescontroller />
        <FontAwesomeIcon
          style={{
            fontSize: 20,
            position: "absolute",
            color: "#000",
            right: 0,
            alignSelf: "center",
          }}
          icon={faCaretRight}
        />
      </div>
      <div
        style={{
          paddingTop: 14,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Switch color="primary" />
      </div>
    </div>
  );
}

export default FlujoIndividual;
