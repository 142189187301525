import React from "react";
import { Form, Input, Switch, Select, InputNumber } from "antd";
import Boton from "../../../design/elementos/boton";
import { setPensando, setStoreWidgets, store } from "../../../../store";
import { Api } from "../../../../api/configApi";
import { ObjectId } from "bson";
import CamposPersonalizados from "./formCamposPersonalizados";
const rules = [{ required: true, message: "Campo Requerido" }];

function CrearActualizarUploadFiles(props) {
  const { Option } = Select;
  const [loading, setLoading] = React.useState(false);
  const [insertOrUpdate, setInsertOrUpdate] = React.useState("InsertWidgets");
  const [formularioGuardado, setFormularioGuardado] = React.useState(false);
  const [form] = Form.useForm();
  const guardarWidget = (values) => {
    values.tipo = "uploadFiles";
    setPensando(true);
    setLoading(true);
    Api("Cuentas", insertOrUpdate, { _id: store.cuentaactiva }, values)
      .then((res) => {
        props.setVisible(false);
        setPensando(false);
        setLoading(false);
        setFormularioGuardado(!formularioGuardado);
        return Api(
          "Cuentas",
          "Get",
          { _id: store.cuentaactiva },
          { widgets: 1 }
        );
      })
      .then((res) => {
        setStoreWidgets(res[0].widgets);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setPensando(false);
        setLoading(false);
      });
  };
  React.useEffect(() => {
    console.log(store.usuario);
    form.resetFields();
    form.setFieldsValue({ _id: new ObjectId(), dinamico: false });
  }, [props.visible]);
  React.useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
      setInsertOrUpdate("UpdateWidgets");
    } else {
      form.setFieldsValue({ _id: new ObjectId(), dinamico: false });
    }
  }, [props.data]);
  return (
    <div>
      <Form form={form} onFinish={guardarWidget} layout="vertical">
        <Form.Item label="Nombre" name="nombre" hasFeedback rules={rules}>
          <Input />
        </Form.Item>
        <Form.Item name="_id" hidden rules={[{ required: true }]}></Form.Item>
        <Form.Item name="dinamico" hidden rules={rules}></Form.Item>

        <Form.Item label="Titulo" name="titulo" hasFeedback rules={rules}>
          <Input />
        </Form.Item>
        <Form.Item
          label="Descripción"
          name="descripcion"
          hasFeedback
          rules={rules}
        >
          <Input />
        </Form.Item>
        {/* aqui campos personalizado.. */}
        <CamposPersonalizados
          formularioGuardado={formularioGuardado}
          formulario={form}
        />
        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: 15,
            }}
          >
            <Boton loading={loading} texto="Guardar" htmlType="submit" />
            <Boton
              loading={loading}
              onClick={() => {
                props.setVisible(false);
              }}
              texto="Cancelar"
              tipo="danger"
              htmlType="button"
            />
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default CrearActualizarUploadFiles;
