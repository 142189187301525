import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Input, message, Modal, Space } from "antd";
import React from "react";
import { Api } from "../../../../../api/configApi";
import { setStoreActualizarLaEstructura } from "../../../../../store";

function CambiarOpcionesAtributo({ atributoActual, setEditarAtributo }) {
  const [guardando, setGuardando] = React.useState(false);
  const [modalNuevaOpcion, setNuevaOpcion] = React.useState(false);

  const [form] = Form.useForm();

  function actualizarOpcion(values) {
    setGuardando(true);
    const nuevasOpciones = atributoActual.opciones.map((opcion) => {
      if (opcion === Object.keys(values)[0]) {
        return values[Object.keys(values)[0]];
      }
      return opcion;
    });

    Api(
      "Cuentas",
      "actualizaAtributoConfig",
      { actualizar: "opcion" },
      {
        opcion: values,
        idAtributo: atributoActual._id,
        nombreAtributo: atributoActual.nombre,
        nuevasOpciones: nuevasOpciones,
      }
    ).then((res) => {
      setEditarAtributo(false);
      setGuardando(false);
    });
  }

  function EliminarOpcion(laOpcion) {
    setGuardando(true);
    const nuevasOpciones = atributoActual.opciones.filter(
      (e) => e !== laOpcion
    );

    Api(
      "cuentas",
      "actualizaAtributoConfig",
      { actualizar: "eliminarOpcion" },
      {
        opcion: laOpcion,
        idAtributo: atributoActual._id,
        nombreAtributo: atributoActual.nombre,
        nuevasOpciones: nuevasOpciones,
      }
    ).then((res) => {
      if (!res.success) {
        message.error(res.message);
        setGuardando(false);
      } else {
        setStoreActualizarLaEstructura();
        setEditarAtributo(false);
        setGuardando(false);
        message.success("Opción Eliminada");
      }
    });
  }

  function NuevaOpcion() {
    setGuardando(true);
    const opcion = form.getFieldValue("opcion");
    Api(
      "Cuentas",
      "actualizaAtributoConfig",
      {
        actualizar: "nuevaOpcion",
      },
      {
        nuevasOpciones: [...atributoActual.opciones, opcion],
        nombreAtributo: atributoActual.nombre,
      }
    ).then((res) => {
      setStoreActualizarLaEstructura();
      setNuevaOpcion(false);
      setGuardando(false);
      setEditarAtributo(false);
    });
  }

  return (
    <>
      <Modal
        visible={modalNuevaOpcion}
        okText="Crear"
        onOk={NuevaOpcion}
        onCancel={() => setNuevaOpcion(false)}
        confirmLoading={guardando}
        closable={false}
        maskClosable={false}
        keyboard={false}
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Nombre de la opción" name="opcion">
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <Space direction="vertical">
        {atributoActual.opciones.map((opcion) => {
          return (
            <Form layout="horizontal" onFinish={actualizarOpcion}>
              <Space direction="horizontal">
                <Form.Item name={opcion} initialValue={opcion}>
                  <Input style={{ marginTop: 10, padding: 3 }} />
                </Form.Item>
                <Button loading={guardando} htmlType="submit">
                  Actualizar
                </Button>
                <FontAwesomeIcon
                  icon={faTrash}
                  color="red"
                  htmlType=""
                  style={{ cursor: "pointer" }}
                  onClick={() => EliminarOpcion(opcion)}
                />
              </Space>
            </Form>
          );
        })}
        <span
          style={{ cursor: "pointer" }}
          onClick={() => setNuevaOpcion(true)}
        >
          + Nueva opción
        </span>
        <Button
          style={{ marginTop: 20 }}
          onClick={() => setEditarAtributo(false)}
          loading={guardando}
        >
          Cerrar
        </Button>
      </Space>
    </>
  );
}

export default CambiarOpcionesAtributo;
