function searchByC(str, tipo) {
  var reg;
  if (tipo === "cuenta") {
    reg = /\w*(?=}})/g;
  } else {
    reg = /\w*(?=])/g;
  }

  var texto = str.match(reg);

  return texto;
}

export default searchByC;
