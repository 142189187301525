import React from "react";
import { Form, Input, Select, Switch, InputNumber, CheckBox } from "antd";
import Boton from "../../../design/elementos/boton";
import {
  setPensando,
  setStoreActualizarLaEstructura,
  setStoreWidgets,
  store,
} from "../../../../store";
import { Api } from "../../../../api/configApi";
import moment from "moment";
import { ObjectId } from "bson";
import AgregarCondicionalesGeneralesWidgetTabla from "./AgregarCondicionalesGeneralesWidgetTabla";
import AgregarCondicionalesAtributosWidgetTabla from "./AgregarCondicionalesAtributosWidgetTabla";

const { Option, OptGroup } = Select;

function CrearActualizarWidgetTabla(props) {
  const [loading, setLoading] = React.useState(false);
  const [cargaInicial, setCargaInicial] = React.useState(true);
  const [listar, setListar] = React.useState("");
  const [buscadorActivo, setBuscadorActivo] = React.useState(false);
  const [insertOrUpdate, setInsertOrUpdate] = React.useState("InsertWidgets");
  const [form] = Form.useForm();
  const didMount = React.useRef(0);

  const guardarWidget = (values) => {
    values.tipo = "tabla";
    setPensando(true);
    setLoading(true);
    Api("Cuentas", insertOrUpdate, { _id: store.cuentaactiva }, values)
      .then((res) => {
        setPensando(false);
        setLoading(false);
        setStoreActualizarLaEstructura();
        props.setVisible(false);
      })
      .catch((err) => {
        console.log(err);
        setPensando(false);
        setLoading(false);
      });
  };
  React.useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      _id: new ObjectId(),
      listar: "afiliadosPropios",
      buscadorActivo: false,
    });
  }, [props.visible]);
  React.useEffect(() => {
    if (props.data) {
      setBuscadorActivo(props.data.buscadorActivo);
      console.log(props.data);

      props.data?.condicionales &&
        props.data.condicionales.forEach((e) => {
          if (e.atributo === "desdeFecha" || e.atributo === "fechaExacta") {
            e.fecha = moment(e.fecha);
            e.tipo = "";
          } else if (e.tipo === "desdeFecha" || e.tipo === "fechaExacta") {
            e.fecha = moment(e.fecha);
          } else if (e.atributo === "rangoFecha" || e.tipo === "rangoFecha") {
            e.rangoFecha[0] = moment(e.rangoFecha[0]);
            e.rangoFecha[1] = moment(e.rangoFecha[1]);
          }
        });

      form.setFieldsValue(props.data);
      setInsertOrUpdate("UpdateWidgets");
    } else {
      form.setFieldsValue({ _id: new ObjectId(), buscadorActivo: false });
    }
  }, [props.data]);

  React.useEffect(() => {
    // valor anterior de listar
    if (didMount.current > 1) {
      // limpiar el campo tipoAfiliado
      form.getFieldValue("tipoAfiliado") &&
        form.setFieldsValue({ tipoAfiliado: "" });
    }
    didMount.current++;

    return () => {
      didMount.current = 0;
    };
  }, [listar]);
  return (
    <div>
      <Form form={form} onFinish={guardarWidget} layout="vertical">
        <Form.Item
          label="Nombre"
          name="nombre"
          hasFeedback
          rules={[{ required: true, message: "Ingresa un nombre" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="_id" hidden rules={[{ required: true }]}></Form.Item>

        <Form.Item
          label="Titulo"
          name="titulo"
          hasFeedback
          rules={[{ required: true, message: "Ingresa un titulo" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Descripción"
          name="descripcion"
          hasFeedback
          rules={[{ required: true, message: "Ingresa una descripción" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Listar"
          name="listar"
          rules={[
            {
              required: true,
              message: "campo obligatorio",
            },
          ]}
          hasFeedback
        >
          <Select
            showSearch
            onChange={() => {
              form.setFieldsValue({
                puedeFiltrar: [],
                columnas: [],
                condicionales: [],
              });
            }}
          >
            <OptGroup label="Afiliados">
              <Option value="todosAfiliados">Todos los afiliados</Option>
              <Option value="afiliadosPropios">Afiliados propios</Option>
            </OptGroup>
            <OptGroup label="Atributos">
              {store.losatributos.numbers.map((e) => {
                return <Option value={"atributo_" + e._id}>{e.nombre}</Option>;
              })}
              {store.losatributos.texts.map((e) => {
                return <Option value={"atributo_" + e._id}>{e.nombre}</Option>;
              })}
            </OptGroup>
            <OptGroup label="otros">
              <Option value="listado">Listado</Option>
            </OptGroup>
          </Select>
        </Form.Item>

        {listar === "listado" && (
          <Form.Item
            rules={[{ required: true, message: "campo obligatorio" }]}
            label="Listado"
            name="listado"
          >
            <Select>
              {store.listados.map((e) => {
                return <Option value={e._id}>{e.nombre}</Option>;
              })}
            </Select>
          </Form.Item>
        )}

        {listar === "todosAfiliados" || listar === "afiliadosPropios" ? (
          <>
            <Form.Item
              label="Tipo de afiliado"
              name="tipoAfiliado"
              rules={[
                {
                  required: true,
                  message: "campo obligatorio",
                },
              ]}
              hasFeedback
            >
              <Select>
                {listar === "todosAfiliados" && (
                  <Option value="todos">Todos</Option>
                )}
                <Option value="enRed">En red</Option>
                <Option value="fueraDeRed">Fuera de la red</Option>
                <Option value="invitadosDirectos">Invitados directos</Option>
              </Select>
            </Form.Item>
            <AgregarCondicionalesGeneralesWidgetTabla
              listar={listar.replace("atributo_", "")}
              atributoActual={form
                .getFieldsValue()
                ?.listar?.replace("atributo_", "")}
              form={form}
            />
          </>
        ) : listar === "listado" ? null : (
          <AgregarCondicionalesAtributosWidgetTabla
            listar={listar?.replace("atributo_", "")}
            atributoActual={form
              .getFieldsValue()
              ?.listar?.replace("atributo_", "")}
          />
        )}

        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues.listar !== curValues.listar
          }
          noStyle
        >
          {({ getFieldsValue }) => {
            const x =
              getFieldsValue().listar === "todosAfiliados" ||
              getFieldsValue().listar === "afiliadosPropios";

            setListar(getFieldsValue().listar);
            return (
              <>
                {x ? (
                  <Form.Item label="Permitir filtrar por" name="puedeFiltrar">
                    <Select mode="multiple">
                      {store.losatributos.texts.map((e, elindex) => {
                        return (
                          <Option key={elindex} value={e._id}>
                            {e.nombre}
                          </Option>
                        );
                      })}
                      {store.losatributos.numbers.map((e, elindex) => {
                        return (
                          <Option key={elindex} value={e._id}>
                            {e.nombre}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                ) : listar === "listado" ? null : (
                  <Form.Item label="Permitir filtrar por" name="puedeFiltrar">
                    <Select mode="multiple">
                      <Option value="debitos">Debitos</Option>
                      <Option value="creditos">Creditos</Option>
                      <Option value="mayorA">mayores a</Option>
                      <Option value="menorA">menores a</Option>
                    </Select>
                  </Form.Item>
                )}
              </>
            );
          }}
        </Form.Item>
        <Form.Item
          name="limiteCantidad"
          label="Cantidad de resultados por página"
          rules={[{ required: true, message: "Campo obligatorio" }]}
          initialValue={100}
        >
          <InputNumber style={{ minWidth: 150 }} max={1000} min={1} />
        </Form.Item>
        {/* <Form.Item
          name="buscadorActivo"
          label="Activar Buscador"
          rules={[{ required: true, message: "Campo obligatorio" }]}
        >
          <Switch defaultChecked={buscadorActivo} />
        </Form.Item> */}
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, curValues) =>
            prevValues.listar !== curValues.listar ||
            prevValues.listado !== curValues.listado
          }
        >
          {({ getFieldsValue }) => {
            const x =
              getFieldsValue().listar === "todosAfiliados" ||
              getFieldsValue().listar === "afiliadosPropios";

            return (
              <>
                <Form.Item
                  rules={[{ required: true }]}
                  label="Permitir ver las columnas:"
                  name="columnas"
                >
                  {x ? (
                    <Select mode="multiple">
                      <OptGroup label="Campos">
                        {store.cuenta.formularios.find(
                          (e) => e.nombre === "Nuevo registro"
                        ) &&
                          store.cuenta.formularios
                            .find((e) => e.nombre === "Nuevo registro")
                            ?.campos.filter((c) => c.name !== "password")
                            .map((e, elindex) => {
                              return (
                                <Option key={elindex} value={e.name}>
                                  {e.name}
                                </Option>
                              );
                            })}
                      </OptGroup>
                      <OptGroup label="Atributos">
                        {[
                          ...store.losatributos.numbers,
                          ...store.losatributos.texts,
                          ...store.losatributos.dates,
                        ].map((e, elindex) => {
                          return (
                            <Option key={e._id} value={"atributo_" + e._id}>
                              {e.nombre}
                            </Option>
                          );
                        })}
                      </OptGroup>
                    </Select>
                  ) : getFieldsValue().listar === "listado" ? (
                    <Select mode="tags">
                      {store.listados
                        .find((e) => e._id === form.getFieldValue("listado"))
                        ?.campos.map((e, elindex) => (
                          <Option key={elindex} value={e.NombreCampo}>
                            {e.NombreCampo}
                          </Option>
                        ))}
                    </Select>
                  ) : (
                    <Select mode="multiple">
                      <Option value="valor">Valor</Option>
                      <Option value="fecha">Fecha Regístro</Option>
                    </Select>
                  )}
                </Form.Item>
              </>
            );
          }}
        </Form.Item>

        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: 15,
            }}
          >
            <Boton loading={loading} texto="Guardar" htmlType="submit" />
            <Boton
              loading={loading}
              onClick={() => {
                props.setVisible(false);
              }}
              texto="Cancelar"
              tipo="danger"
              htmlType="button"
            />
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default CrearActualizarWidgetTabla;
