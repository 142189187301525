import React from "react";
import { Divider, Form, Input, Button, Checkbox, Select } from "antd";
import Boton from "../../../design/elementos/boton";
import { setPensando, setStoreWidgets, store } from "../../../../store";
import { Api } from "../../../../api/configApi";
import { ObjectId } from "bson";

function CrearActualizarWidgetAdminRecursos(props) {
  const { Option } = Select;
  const [loading, setLoading] = React.useState(false);
  const [insertOrUpdate, setInsertOrUpdate] = React.useState("InsertWidgets");
  const [form] = Form.useForm();
  const onFinish = (values) => {
    values.tipo = "adminRecursos";

    setPensando(true);
    setLoading(true);
    Api("Cuentas", insertOrUpdate, { _id: store.cuentaactiva }, values)
      .then((res) => {
        props.setVisible(false);

        setPensando(false);
        setLoading(false);
        return Api(
          "Cuentas",
          "Get",
          { _id: store.cuentaactiva },
          { widgets: 1 }
        );
      })
      .then((res) => {
        setStoreWidgets(res[0].widgets);
      })
      .catch((err) => {
        setPensando(false);
        setLoading(false);
      });
  };
  React.useEffect(() => {
    form.resetFields();
    form.setFieldsValue({ _id: new ObjectId() });
    console.log(store.cuenta.modulos);
  }, [props.visible]);
  React.useEffect(() => {
    if (props.data) {
      form.setFieldsValue(props.data);
      setInsertOrUpdate("UpdateWidgets");
    } else {
      form.setFieldsValue({ _id: new ObjectId() });
    }
  }, [props.data]);
  return (
    <div>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          label="Nombre"
          name="nombre"
          hasFeedback
          rules={[{ required: true, message: "Ingresa un nombre" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="_id" hidden rules={[{ required: true }]}></Form.Item>

        <Form.Item
          label="Titulo"
          name="titulo"
          hasFeedback
          rules={[{ required: true, message: "Ingresa un titulo" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Descripción"
          name="descripcion"
          hasFeedback
          rules={[{ required: true, message: "Ingresa una descripción" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Seleccionar el modulo de recursos"
          name="moduloRecursos"
          rules={[
            {
              required: true,
              message: "Seleccionar un modulo de recursos ",
            },
          ]}
          hasFeedback
        >
          <Select placeholder="Modulo de recursos">
            {store.cuenta.modulos
              .filter((e) => e.nombre === "Recursos")
              .map((modulo) => {
                return (
                  <Option key={modulo._id} value={modulo._id}>
                    {modulo.campos[0].value}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: 15,
            }}
          >
            <Boton loading={loading} texto="Guardar" htmlType="submit" />
            <Boton
              loading={loading}
              onClick={() => {
                props.setVisible(false);
              }}
              texto="Cancelar"
              tipo="danger"
              htmlType="button"
            />
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default CrearActualizarWidgetAdminRecursos;
