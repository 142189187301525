import React from "react";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { message, Popconfirm, notification, Checkbox } from "antd";
import { Api } from "../../../../api/configApi";
import ModalResultadosEtapa from "./pulpitos/modalResultadosEtapa";

function PlayPulpitos({ etapa }) {
  async function Play() {
    notification.open({
      message: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img width={30} style={{cursor: "pointer"}} alt="cuantosPulpitos" src="https://octopusmultinivel.b-cdn.net/octopus10/octopus.svg" />
          <span style={{ marginLeft: 10 }}>{etapa.nombre}</span>
        </div>
      ),
      description: <ModalResultadosEtapa etapa={etapa} tipo="etapa" flujo={null} />,
      duration: 0,
      placement: "topLeft",
    });
  }
  return (
    <Popconfirm
      onConfirm={Play}
      title={
        <div style={{ maxWidth: 200 }}>
          <strong>Consultar</strong>
          <p>
            <span style={{ fontSize: 14 }}>
              Verificaremos cuantos pulpitos cumplen las condiciones de esta
              etapa
            </span>
          </p>
        </div>
      }
    >
      
      <img width={30} style={{cursor: "pointer"}} alt="cuantosPulpitos" src="https://octopusmultinivel.b-cdn.net/octopus10/octopus.svg" />
    </Popconfirm>
  );
}

export default PlayPulpitos;
