import { Collapse, Space } from "antd";
import IntegracionSendGrid from "./sendGrid/integracionSendGrid";
import IntegracionFad from "./FAD/integracionFad";
import IntegracionUploadCare from "./uploadCare/integracionUploadCare";

const { Panel } = Collapse;

function RenderIntegraciones() {
  return (
    <div>
      <Collapse accordion>
        <Panel
          header={
            <Space size="large">
              <img
                width={80}
                alt="senGrid"
                src="https://octopusmultinivel.b-cdn.net/octopus10/SendGrid_2016_Logo.png"
              />
              <span>Envio de correo electrónico</span>
            </Space>
          }
          key="1"
        >
          <IntegracionSendGrid />
        </Panel>
      </Collapse>
      <Collapse accordion>
        <Panel
          header={
            <Space size="large">
              <img
                width={50}
                alt="fad"
                src="https://uatclientes.firmaautografa.com/assets/img/backoffice/@0.5xlogo_inicio.svg"
              />
              <span>Firma Digital</span>
            </Space>
          }
          key="2"
        >
          <IntegracionFad />
        </Panel>
      </Collapse>
      <Collapse accordion>
        <Panel
          header={
            <Space size="large">
              <img
                width={150}
                alt="uploadCare"
                src="https://assets.website-files.com/6013a75e1dceb5e15f07a67a/6013d27c283fef836ceb0170_uploadcare-logo.png"
              />
              <span>Upload Care. Permite la subida y consulta de archivos</span>
            </Space>
          }
          key="2"
        >
          <IntegracionUploadCare />
        </Panel>
      </Collapse>
    </div>
  );
}

export default RenderIntegraciones;
