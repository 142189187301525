import React, { useEffect } from "react";
import { setCuentaActiva, setMostrarmenu, setPensando, setStoreActualizarLaEstructura } from "../store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {store, setNombrecuenta} from '../store'
import { Api } from "../api/configApi"
function Cuentas(props) {
  const [cuentas, setCuentas] = React.useState([]);
  const [abriendocuenta, setAbriendocuenta] = React.useState(false);
  const history = useHistory();
  function abrircuenta(id, nombre, modulos) {
    setPensando(true);
    Api("usuarios", "Update", { cuentaactiva: id }).then(res=>{
      setNombrecuenta(nombre);
      setCuentaActiva(id);
      setPensando(false);
      setMostrarmenu(true);
      history.push("/");
      setStoreActualizarLaEstructura(false);
    }).catch(err=>{
      console.log(err);
    });
  }

  useEffect(() => {
    console.log("Interesante ante....");
    setMostrarmenu(false);
    Api("cuentas", "Get").then((res) => {
      setPensando(false);
      setCuentas(res);
      res.length === 0 && history.push("/abrircuenta");
    });
  }, [history]);

  return (
    cuentas.length === 0 && (
      <div
        style={{
          width: "100%",
          height: "100%",
          alignItems: "center",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Backdrop style={{ zIndex: 3 }} open={abriendocuenta}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {cuentas.map((cuenta) => {
            return (
              <div
                onClick={() => {
                  abrircuenta(cuenta._id, cuenta.nombre, cuenta.modulos);
                  
                }}
                key={cuenta._id.toString()}
                style={{ margin: 10, cursor: "pointer" }}
              >
                <div
                  style={{
                    background: "rgb(224 224 224)",
                    fontSize: 42,
                    padding: 20,
                    borderRadius: 10,
                    marginBottom: 5,
                    textAlign: "center",
                    color: "#353535",
                  }}
                >
                  {cuenta.nombre.slice(0, 3).toUpperCase()}
                </div>
                <div style={{ textAlign: "center" }}>{cuenta.nombre}</div>
              </div>
            );
          })}
          <div
            style={{
              margin: 10,
              borderRadius: 5,
              borderStyle: "dotted",
              borderColor: "rgb(206 206 206 / 93%)",
              cursor: "pointer",
              display: "flex",
            }}
            onClick={() => {
              history.push("/abrircuenta");
            }}
          >
            <FontAwesomeIcon
              style={{
                fontSize: 60,
                margin: 20,
                color: "rgb(206 206 206 / 93%)",
                alignSelf: "center",
              }}
              icon={faPlus}
            />
          </div>
        </div>
      </div>
    )
  );
}

export default Cuentas;
