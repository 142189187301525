import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import Button from "@material-ui/core/Button";
import Nuevomodulo from "../components/modals/formularios/nuevomodulo";
import { useLayer, useHover, Arrow } from "react-laag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useParams } from "react-router";
import Boton from "./design/elementos/boton";
import { store } from "../store";

function Titulares(props) {
  const [ancla, setancla] = React.useState();
  const [isOpen, setOpen] = React.useState(false);
  const params = useParams();
  const history = useHistory();
  const { renderLayer, triggerProps, layerProps, arrowProps } = useLayer({
    isOpen,
    auto: true,
    arrowOffset: 4,
    placement: "bottom-center",
    triggerOffset: 10,
    onOutsideClick: () => setOpen(false),
  });
  React.useEffect(() => {
    console.log(history);
  }, []);
  return (
    <div>
      <div>
        {params.idgrupo && (
          <FontAwesomeIcon
            onClick={() => {
              history.push("/configuraciones/index/flujos");
            }}
            style={{ cursor: "pointer",  marginRight: 10, fontSize: 15, marginBottom: 10 }}
            icon={faArrowLeft}
          />
        )}

        <span
          style={{
            fontSize: "1rem",
            lineHeight: "1.5rem",
            marginBottom: ".5rem",
            color: "#8094ae",
            fontWeight: 400,
            position: "relative",
          }}
        >
          {props.navegacion}
        </span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          <h2
            style={{
              fontWeight: 400,
              fontSize: "2.5rem",
              letterSpacing: "-0.03em",
              marginTop: 5,
              marginBottom: 5,
              lineHeight: "1.1",
              color: "#364a63",
            }}
          >
            {props.titular}
          </h2>

          <div>
            <p
              style={{
                color: "#526484",
                fontSize: ".875rem",
                fontWeight: 400,
                lineHeight: 1.65,
                margin: 0,
              }}
            >
              {props.contenido}
              <span className="text-primary">
                <em className="icon ni ni-info"></em>
              </span>
            </p>
          </div>
        </div>
        <div style={{ position: "relative" }}>
          {props.boton && (
            <>
              {/* <Button
                onClick={(event) => { setancla(event.currentTarget); console.log(event.currentTarget); }}
                variant="outlined"
                color="primary"
              >
                {props.boton.texto}
              </Button> */}
              <div {...triggerProps} onClick={() => setOpen(!isOpen)}>
                <Boton texto={props.boton.texto} />
              </div>

              {renderLayer(
                <AnimatePresence>
                  {isOpen && (
                    <motion.div
                      {...layerProps}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <Nuevomodulo setOpen={setOpen} />
                      <Arrow {...arrowProps} size={5} roundness={0} />
                    </motion.div>
                  )}
                </AnimatePresence>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Titulares;
