import React from "react";
import Tree from "react-d3-tree";

import { store } from "../../../../../../store";
import { Button, Popover, Alert, Divider } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Api } from "../../../../../../api/configApi";

function DefaultDesignArbol(props) {
  const [widget, setWidget] = React.useState(props.widget);
  const [tieneNodo, setTieneNodo] = React.useState(true);
  const [afiliados, setAfiliados] = React.useState({
    id: "",
    name: "",
    children: [],
  });
  const [leftArbol, setLeftArbol] = React.useState(50);
  const [topArbol, setTopArbol] = React.useState(50);
  const [zoom, setZoom] = React.useState(1);
  const ref = React.useRef();

  const MiniPerfil = (props) => {
    const nodo = props.nodo;

    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            maxWidth: 200,
            flexWrap: "wrap",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", margin: 5 }}>
            <span style={{ fontSize: 10 }}>Nombre</span>
            <strong>{nodo.name}</strong>
            <Divider />
          </div>
        </div>
      </>
    );
  };

  React.useEffect(() => {
    Api("red", "listRed", { tipoRed: "unilevel" })
      .then((res) => {
        console.log(res);
        if (res?.success === false) {
          setTieneNodo(false);
        } else {
          setTieneNodo(true);
          setAfiliados(res);
        }
      })
      .catch((er) => {
        console.log(er);
      });
    setLeftArbol(ref.current.offsetWidth / 2);
  }, [widget]);

  const Nodo = ({ e }) => {
    return (
      <g>
        <defs>
          <filter x="0" y="0" width="1" height="1" id="solid">
            <feFlood flood-color="#fff" result="bg" />
            <feMerge>
              <feMergeNode in="bg" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        <circle
          style={{ stroke: "rgb(226 226 226)", fill: "#fff" }}
          r="25"
        ></circle>
        <Popover
          content={() => {
            return <MiniPerfil nodo={e.nodeDatum} />;
          }}
          title={""}
        >
          <g>
            <image
              onClick={() => {
                clickNodo(e);
              }}
              style={{
                transform: "translate(-20px, -20px)",
                borderRadius: "50%",
                opacity: 1,
              }}
              href="https://octopusmultinivel.b-cdn.net/octopus10/man.svg"
              height="40"
              width="40"
            />
          </g>
        </Popover>

        {e.nodeDatum.children.length > 0 && e.nodeDatum.__rd3t.collapsed && (
          <g>
            <image
              height="12"
              width="12"
              style={{ x: "1%", y: "1%" }}
              href="https://octopusmultinivel.b-cdn.net/octopus10/plus.svg"
            />
          </g>
        )}
        {/* <text
          filter="url(#solid)"
          y="3.7%"
          strokeWidth="0"
          fill="#000"
          fontSize={11}
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          {e.nodeDatum.name}
        </text> */}
      </g>
    );
  };

  function clickNodo(e) {
    e.toggleNode();
  }

  return (
    <>
      {tieneNodo && (
        <div
          style={{
            position: "absolute",
            right: 10,
            top: 10,
            width: 50,
            height: 100,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button type="text" style={{ cursor: "pointer" }}>
            <FontAwesomeIcon
              style={{ fontSize: 20 }}
              onClick={() => {
                zoom < 1.3 && setZoom(zoom + 0.1);
              }}
              icon={faPlus}
            />
          </Button>
          <Button type="text" style={{ cursor: "pointer", marginTop: 10 }}>
            <FontAwesomeIcon
              style={{ fontSize: 20 }}
              onClick={() => {
                zoom >= 0.3 && setZoom(zoom - 0.1);
              }}
              icon={faMinus}
            />
          </Button>
        </div>
      )}

      <div ref={ref} id="treeWrapper" style={{ width: "100%", height: "30em" }}>
        {!tieneNodo ? (
          <Alert
            style={{ marginBottom: 30 }}
            message="No tienes aún una posición en la red"
            type="warning"
          />
        ) : (
          <Tree
            orientation="vertical"
            enableLegacyTransitions={true}
            onNodeMouseOver={clickNodo}
            renderCustomNodeElement={(e) => {
              return <Nodo e={e} />;
            }}
            translate={{ x: leftArbol, y: topArbol }}
            zoomable={false}
            zoom={zoom}
            data={afiliados}
            scaleExtent={{ max: 2 }}
            nodeSize={{ x: 100, y: 100 }}
          />
        )}
      </div>
    </>
  );
}

const estilos = {
  contenido: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    fontSize: 30,
    fontWeight: 400,
  },
};

export default DefaultDesignArbol;
