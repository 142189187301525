import React from "react";
import { useHistory, useParams } from "react-router-dom";
import Submenu from "../../../components/submenu";
import PagesConfigDiseñoIdentidadvisual from "./pagesConfigDiseñoidentidadvisual";
import Boton from "../../../components/design/elementos/boton"
import PageWidgetsIndex from "../widgets/PageWidgetsIndex"
import PageMenuAfiliado from "../menu/PageMenuAfiliado";
import ListaFormulariosPage from "../formularios/listaFormulariosPage";
import PageMenuAdministrador from "../menu/PageMenuAdministrador";
import PageMenuApp from "../menu/PageMenuApp";

function PagesConfigDiseñoIndex() {
  let history = useHistory();
  let params = useParams();
  const [tableros, setTableros] = React.useState([
    {
      nombre: "Identidad Visual",
      id: "identidadvisual",
      _id: "identidadvisual",
    },
    {
      nombre: "Widgets",
      id: "widgets",
      _id: "widgets",
    },
    {
      nombre: "Menu Afiliado",
      id: "menu-afiliado",
      _id: "menu-afiliado",
    },
    {
      nombre: "Menu Administrador",
      id: "menu-administrador",
      _id: "menu-administrador",
    },
    {
      nombre: "Menu App",
      id: "menu-app",
      _id: "menu-app",
    },
    {
      nombre: "Formularios",
      id: "menu-formularios",
      _id: "menu-formularios",
    },
  ]);
  React.useEffect(() => {
    console.log(params.id);
  }, [params]);
  return (
    <div>
      <Submenu tableros={tableros} />
      
      {params.id === "identidadvisual" && <PagesConfigDiseñoIdentidadvisual />}
      {params.id === "widgets" && <PageWidgetsIndex />}
      {params.id === "menu-afiliado" && <PageMenuAfiliado />}
      {params.id === "menu-administrador" && <PageMenuAdministrador />}
      {params.id === "menu-app" && <PageMenuApp />}
      {params.id === "menu-formularios" && <ListaFormulariosPage />}
    </div>
  );
}

export default PagesConfigDiseñoIndex;
