import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  notification,
  Select,
  Space,
  Switch,
} from "antd";
import React from "react";
import { Api } from "../../../../api/configApi";

import { setStoreActualizarLaEstructura, store } from "../../../../store";

var campoObligatorio = [{ required: true, message: "Campo obligatorio" }];
const { Option } = Select;

function FormCrearListado({ setDrawerCrearListado, listadoActual }) {
  const [form] = Form.useForm();
  const [insertOrUpdate, setInsertOrUpdate] = React.useState("InsertListado");
  const [loading, setLoading] = React.useState(false);
  const refListaNombreCampoModificados = React.useRef({
    nombreLista: "",
    camposModificados: [],
    camposEliminados: [],
    opcionesEliminadas: [],
    opcionesModificadas: [],
  });
  const refListaNombreCampoAntesDeModificar = React.useRef([]);
  const refBanderaSelect = React.useRef(false);

  function comunicacionBack() {
    if (form.getFieldsValue().campos.length == 0) {
      message.error("Debes tener al menos un campo para tu listado");
      return;
    }

    form.getFieldsValue().campos.forEach((campo) => {
      if (campo.NombreCampo == "") {
        message.error("Debes tener un nombre para cada campo");
        return;
      }
    });

    listadoActual &&
      Api("listados", "UpdateItemsList", {
        camposactualizar: refListaNombreCampoModificados.current,
        listado_id: listadoActual._id,
      }).then((resp) => {
        setStoreActualizarLaEstructura(true);
      });
  }

  function banderaSelect(key) {
    if (listadoActual != null) {
      if (key < listadoActual.campos.length) {
        return true;
      } else {
        return false;
      }
    }
  }

  function guardarNombreModificados(key, campoMod, index) {
    campoMod = campoMod.trim();
    const campoActual = form.getFieldsValue().campos[index];
    const nombreLista = form.getFieldsValue().nombre;

    if (key <= listadoActual?.campos.length) {
      refListaNombreCampoModificados.current.nombreLista = nombreLista;
      let obj = refListaNombreCampoAntesDeModificar.current.find(
        (campo) => campo.id === campoActual.id
      );

      if (obj) {
        refListaNombreCampoModificados.current.camposModificados =
          refListaNombreCampoModificados.current.camposModificados.filter(
            (campo) => campo.id !== campoActual.id
          );
        if (obj?.nombreAnterior !== campoMod) {
          refListaNombreCampoModificados.current.camposModificados.push({
            nuevoNombre: campoMod,
            viejoNombre: obj.nombreAnterior,
            id: campoActual.id,
          });
        }
      }
    }
  }

  function guardarOpcionesEliminadas(index, index2) {
    if (refListaNombreCampoModificados.current.opcionesEliminadas.length != 0) {
      if (
        refListaNombreCampoModificados.current.opcionesEliminadas.some(
          (campos) =>
            campos.nombreCampo ==
            form.getFieldsValue().campos[index].NombreCampo
        )
      ) {
        let indice;
        refListaNombreCampoModificados.current.opcionesEliminadas.forEach(
          (campo, ind) => {
            if (
              campo.nombreCampo ==
              form.getFieldsValue().campos[index].NombreCampo
            ) {
              indice = ind;
            }
          }
        );
        refListaNombreCampoModificados.current.opcionesEliminadas[
          indice
        ].opcionesElim.push(
          form.getFieldsValue().campos[index].opciones[index2].opcion
        );
      } else {
        refListaNombreCampoModificados.current.opcionesEliminadas.push({
          nombreCampo: form.getFieldsValue().campos[index].NombreCampo,
          opcionesElim: [
            form.getFieldsValue().campos[index].opciones[index2].opcion,
          ],
        });
      }
    } else {
      refListaNombreCampoModificados.current.opcionesEliminadas.push({
        nombreCampo: form.getFieldsValue().campos[index].NombreCampo,
        opcionesElim: [
          form.getFieldsValue().campos[index].opciones[index2].opcion,
        ],
      });
    }

    refListaNombreCampoModificados.current.nombreLista =
      form.getFieldsValue().nombre;
  }

  function guardarOpcionesModificadas(value, index, index2) {
    const elementoActual = form.getFieldsValue().campos[index].opciones[index2];
    const elementoAnterior = refListaNombreCampoAntesDeModificar.current
      .find((e) => e.id === elementoActual.idCampo)
      ?.opciones.find((e) => e.id === elementoActual.id);

    refListaNombreCampoModificados.current.opcionesModificadas =
      refListaNombreCampoModificados.current.opcionesModificadas.filter(
        (opcion) => opcion.id !== elementoActual.id
      );

    if (
      elementoActual.id &&
      elementoActual.opcion.trim() !== elementoAnterior.opcion
    ) {
      refListaNombreCampoModificados.current.opcionesModificadas.push({
        nombreCampo: form.getFieldsValue().campos[index].NombreCampo,
        nombreNuevo: elementoActual.opcion.trim(),
        nombreViejo: elementoAnterior.opcion,
        id: elementoActual.id,
      });
    }

    refListaNombreCampoModificados.current.nombreLista =
      form.getFieldsValue().nombre;
  }

  function guardarNombreAntesDeModificar() {
    form.getFieldsValue().campos.forEach((campo) => {
      refListaNombreCampoAntesDeModificar.current.push({
        nombreAnterior: campo.NombreCampo,
        id: campo.id,
        opciones: campo.opciones || [],
      });
    });
  }

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  React.useEffect(() => {
    if (listadoActual?.campos) {
      setInsertOrUpdate("UpdateListado");
      listadoActual?.campos.forEach((campo) => {
        campo.id = getRandomInt(100000, 1000000000);
        campo.opciones?.forEach((opcion) => {
          opcion.id = getRandomInt(100000, 1000000000);
          opcion.idCampo = campo.id;
        });
      });

      form.setFieldsValue(listadoActual);
      refBanderaSelect.current = true;
      guardarNombreAntesDeModificar();
    } else {
      form.resetFields();
      setInsertOrUpdate("InsertListado");
    }
  }, [listadoActual]);

  function guardarListado(values) {
    setLoading(true);
    if (listadoActual) values._id = listadoActual._id;

    Api("Cuentas", insertOrUpdate, { _id: store.cuentaactiva }, values).then(
      (re) => {
        if (re.success) {
          message.success("Listado guardado");

          setLoading(false);
          form.resetFields();
          setDrawerCrearListado(false);
          setStoreActualizarLaEstructura(true);
        } else {
          message.error("Algo no salio bien");
        }
      }
    );
  }

  return (
    <>
      <Form form={form} layout="vertical" onFinish={guardarListado}>
        <Form.Item
          rules={campoObligatorio}
          name="nombre"
          label="Nombre del listado"
        >
          <Input
            placeholder="Agregar nombre del listado"
            onBlur={(e) => {
              refListaNombreCampoModificados.current.nombreLista =
                form.getFieldsValue().nombre;
            }}
          />
        </Form.Item>
        <strong>Campos</strong>
        <div
          style={{ marginTop: 10, background: "rgb(249 249 249)", padding: 20 }}
        >
          <Form.List name="campos" label="Campos" rules={campoObligatorio}>
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map(
                    ({ key, name, fieldKey, ...restField }, index) => (
                      <>
                        <Space key={fieldKey} direction="horizontal">
                          <Space
                            style={{
                              display: "flex",
                              marginBottom: 8,
                              flexDirection: "column",
                            }}
                            align="baseline"
                          >
                            <Space style={{ display: "flex" }}>
                              <Form.Item
                                style={{ minWidth: 300 }}
                                label="Nombre Campo"
                                {...restField}
                                name={[name, "NombreCampo"]}
                                fieldKey={[fieldKey, "NombreCampo"]}
                                rules={[
                                  {
                                    required: "true",
                                    message:
                                      "Debes seleccionar un nombre para el campo",
                                  },
                                  {
                                    validator: (rule, value) => {
                                      if (
                                        value.split(".").length > 1 ||
                                        value.split("$").length > 1
                                      ) {
                                        return Promise.reject(
                                          "Esta no es una opción valida"
                                        );
                                      } else {
                                        return Promise.resolve();
                                      }
                                    },
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Nombre del campo"
                                  onBlur={(e) => {
                                    if (listadoActual !== null) {
                                      guardarNombreModificados(
                                        key,
                                        e.target.value,
                                        index
                                      );
                                    }
                                  }}
                                />
                              </Form.Item>

                              <Form.Item name={[name, "id"]}>
                                <Input hidden></Input>
                              </Form.Item>

                              <Form.Item
                                style={{ minWidth: 300 }}
                                {...restField}
                                name={[name, "tipoCampo"]}
                                fieldKey={[fieldKey, "tipoCampo"]}
                                label="Tipo de campo"
                                rules={[
                                  {
                                    required: "true",
                                    message:
                                      "Debes seleccionar un tipo de campo",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Seleccione un tipo de campo"
                                  disabled={banderaSelect(key)}
                                >
                                  <Option value="multiple">
                                    Opción multiple
                                  </Option>
                                  <Option value="numero">Número</Option>
                                  <Option value="fecha">Fecha</Option>
                                  <Option value="switch">Switch</Option>
                                  <Option value="url">Url</Option>
                                  <Option value="imagen">Imagen</Option>
                                  <Option value="texto">Texto</Option>
                                </Select>
                              </Form.Item>
                              <Form.Item
                                initialValue={false}
                                label="Obligatorio"
                                {...restField}
                                name={[name, "obligatorio"]}
                                fieldKey={[fieldKey, "obligatorio"]}
                                valuePropName="checked"
                              >
                                <Switch />
                              </Form.Item>
                            </Space>
                            <Form.Item shouldUpdate noStyle>
                              {(elf) =>
                                elf.getFieldsValue().campos[index]
                                  ?.tipoCampo === "multiple" && (
                                  <Form.List
                                    name={[name, "opciones"]}
                                    label="Opciones"
                                    rules={[
                                      {
                                        required: "true",
                                        message:
                                          "Debes seleccionar un nombre para el campo",
                                      },
                                    ]}
                                  >
                                    {(fields, { add, remove }) => {
                                      return (
                                        <>
                                          {fields.map(
                                            (
                                              {
                                                key2,
                                                name,
                                                fieldKey,
                                                ...restField
                                              },
                                              index2
                                            ) => (
                                              <>
                                                <Space
                                                  key={fieldKey}
                                                  direction="horizontal"
                                                >
                                                  <Space align="baseline">
                                                    <Form.Item
                                                      name={[name, "opcion"]}
                                                      rules={[
                                                        {
                                                          required: "true",
                                                          message:
                                                            "Debes seleccionar un nombre para el campo",
                                                        },
                                                        {
                                                          validator: (
                                                            rule,
                                                            value
                                                          ) => {
                                                            if (
                                                              value.split(".")
                                                                .length > 1 ||
                                                              value.split("$")
                                                                .length > 1
                                                            ) {
                                                              return Promise.reject(
                                                                "Esta no es una opción valida"
                                                              );
                                                            } else {
                                                              return Promise.resolve();
                                                            }
                                                          },
                                                        },
                                                        {
                                                          validator: (
                                                            rule,
                                                            value
                                                          ) => {
                                                            if (
                                                              form
                                                                .getFieldsValue()
                                                                .campos[
                                                                  index
                                                                ].opciones.filter(
                                                                  (opcion) =>
                                                                    opcion.opcion ==
                                                                    value
                                                                ).length > 1
                                                            ) {
                                                              return Promise.reject(
                                                                "El valor de esta opción ya está en uso"
                                                              );
                                                            } else {
                                                              return Promise.resolve();
                                                            }
                                                          },
                                                        },
                                                      ]}
                                                    >
                                                      <Input
                                                        placeholder="Agregar opción"
                                                        style={{
                                                          marginTop: "24px",
                                                        }}
                                                        onBlur={(e) => {
                                                          if (
                                                            listadoActual !==
                                                            null
                                                          ) {
                                                            guardarOpcionesModificadas(
                                                              e.target.value,
                                                              index,
                                                              index2
                                                            );
                                                            console.log();
                                                          }
                                                        }}
                                                      ></Input>
                                                    </Form.Item>
                                                  </Space>
                                                  <div
                                                    style={{
                                                      cursor: "pointer",
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={faMinusCircle}
                                                      onClick={(e) => {
                                                        if (
                                                          listadoActual !== null
                                                        ) {
                                                          guardarOpcionesEliminadas(
                                                            index,
                                                            index2
                                                          );
                                                        }
                                                        remove(name);
                                                      }}
                                                    />
                                                  </div>
                                                </Space>
                                                <Divider />
                                              </>
                                            )
                                          )}
                                          <Form.Item
                                            style={{ textAlign: "center" }}
                                          >
                                            <Button
                                              type="dashed"
                                              onClick={() => {
                                                add();
                                              }}
                                              style={{ float: "left" }}
                                            >
                                              Agregar opción
                                            </Button>
                                          </Form.Item>
                                        </>
                                      );
                                    }}
                                  </Form.List>
                                )
                              }
                            </Form.Item>
                          </Space>
                          <div style={{ cursor: "pointer" }}>
                            <FontAwesomeIcon
                              icon={faMinusCircle}
                              onClick={(e) => {
                                if (
                                  !refListaNombreCampoModificados.current.camposEliminados.includes(
                                    form.getFieldsValue().campos[index]
                                      .NombreCampo
                                  )
                                ) {
                                  refListaNombreCampoModificados.current.nombreLista =
                                    form.getFieldsValue().nombre;
                                  refListaNombreCampoModificados.current.camposEliminados.push(
                                    form.getFieldsValue().campos[index]
                                      .NombreCampo
                                  );
                                }
                                remove(name);
                              }}
                            />
                          </div>
                        </Space>
                        <Divider />
                      </>
                    )
                  )}
                  <Form.Item style={{ textAlign: "center" }}>
                    <Button
                      type="dashed"
                      onClick={() => {
                        add();
                      }}
                      style={{ width: "60%" }}
                    >
                      Agregar campo
                    </Button>
                  </Form.Item>
                </>
              );
            }}
          </Form.List>
        </div>

        <Button
          style={{ marginTop: 80 }}
          htmlType="submit"
          loading={loading}
          onClick={comunicacionBack}
        >
          Guardar Listado
        </Button>
      </Form>
    </>
  );
}

export default FormCrearListado;
