import React, { useRef } from "react";
import calculateSize from "calculate-size";
import { Api } from "../../../../api/configApi";
import { setPensando, setStoreGrupoFlujos, store } from "../../../../store";
import { ObjectId } from "bson";
import { InputLabel, MenuItem } from "@material-ui/core";
import ReactTags from "react-tag-autocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Form, Select, Input, message } from "antd";
import Boton from "../../../../components/design/elementos/boton";

const { Option } = Select;

function CrearAtributo(props) {
  const [anchoInputGrupo, setAnchoInputGrupo] = React.useState(200);
  const [anchoInputDescripcion, setAnchoInputGrupoDescripcion] =
    React.useState(200);
  const [tipoAtributo, setTipoAtributo] = React.useState("number");
  const [mostrarOpciones, setMotrarOpciones] = React.useState(false);
  const [tags, setTags] = React.useState([]);
  const [tagsArray, setTagsArray] = React.useState([]);
  const sugerencias = [];
  const reactTags = useRef();
  const [nombreAtributo, setNombreAtributo] = React.useState("");
  const [descripcionAtributo, setDescripcionAtributo] = React.useState("");
  React.useEffect(() => {
    const newOpcions = [];
    tags.forEach((element) => {
      newOpcions.push(element.name);
    });
    setTagsArray(newOpcions);
  }, [tags]);
  React.useEffect(() => {
    setAnchoInputGrupo(calculateSize(nombreAtributo, { fontSize: "18px" }));
    setAnchoInputGrupoDescripcion(
      calculateSize(descripcionAtributo, { fontSize: "18px" })
    );
  }, [nombreAtributo, descripcionAtributo]);
  React.useEffect(() => {
    if (tipoAtributo !== "text") {
      setMotrarOpciones(false);
    } else {
      setMotrarOpciones(true);
    }
  }, [tipoAtributo]);
  const CrearElAtributo = (values) => {
    setPensando(true);
    const atributos = { ...store.losatributos };
    const yaExiste = atributos[values.tipo + "s"].find(
      (e) => e.nombre.toLowerCase() === values.nombre.toLowerCase()
    );
    if (yaExiste) {
      message.error("El nombre de este atributo ya existe");
      setPensando(false);
      return;
    }

    atributos[values.tipo + "s"].unshift({
      tipo: values.tipo,
      nombre: values.nombre,
      descripcion: values.descripcion,
      opciones: values.opciones || [],
      filtros: [],
      _id: new ObjectId(),
    });

    Api(
      "cuentas",
      "UpdateAtributos",
      { _id: store.cuentaactiva },
      { atributos: atributos }
    )
      .then((r) => {
        props.setActualizarAtributos(!props.actualizarAtributos);
        setPensando(false);
        props.setCrearAtributo(false);
      })
      .catch((err) => {
        setPensando(false);
        console.log(err);
      });
  };
  const onDelete = (i) => {
    const tagss = tags.slice(0);
    tagss.splice(i, 1);
    setTags(tagss);
  };

  const onAddition = (tag) => {
    const tagss = [].concat(tags, tag);

    setTags(tagss);
  };
  function TagComponent({ tag, removeButtonText, onDelete }) {
    return (
      <div className="ContenedorTagsAtributos">
        <span>{tag.name}</span>
        <FontAwesomeIcon
          onClick={onDelete.bind(this)}
          style={{ cursor: "pointer" }}
          icon={faTimes}
        />
      </div>
    );
  }

  const opciones = [];

  return (
    <Form onFinish={CrearElAtributo} layout="vertical">
      <Form.Item
        rules={[{ required: true, message: "Campo obligatorio" }]}
        name="nombre"
        label="nombre"
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "Campo obligatorio" }]}
        name="descripcion"
        label="Descripción"
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[{ required: true, message: "Campo obligatorio" }]}
        name="tipo"
        label="Tipo"
      >
        <Select>
          <Option value="text">Texto</Option>
          <Option value="number">Número</Option>
          <Option value="date">Fecha</Option>
        </Select>
      </Form.Item>
      <Form.Item shouldUpdate>
        {({ getFieldsValue }) => {
          return (
            getFieldsValue().tipo === "text" && (
              <Form.Item label="opciones" name="opciones">
                <Select mode="tags"> {opciones}</Select>
              </Form.Item>
            )
          );
        }}
      </Form.Item>

      <Boton texto="Guardar" htmlType="submit" />
    </Form>
  );
}

export default CrearAtributo;
