import React from "react";
import { Button, Popover, Input, Space, Modal, message } from "antd";
import "antd/dist/antd.css";
import { Api } from "../../../../../../../api/configApi";

function CampoTextoComponente(props) {
  const [visible, setVisible] = React.useState(false);
  const [value, setValue] = React.useState(props.valor);
  const refValue = React.useRef(value);
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const actualizar = (data) => {
    Api(
      "listados",
      "UpdateItem",
      {
        actualizarItem: data,
      },
      {},
      "https://f399-181-132-3-197.ngrok.io"
    ).then((res) => {
      message.success("Se actualizó el campo");
      props.Get();
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    if (refValue.current && refValue.current !== props.valor) {
      setValue(refValue.current);
      actualizar({
        valor: refValue.current,
        idCampo: props.id,
        idListado: props.idListado,
        nombreCampo: props.nombreCampo,
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {props.valor !== "" ? (
        <p onClick={showModal}>{value}</p>
      ) : (
        <p onClick={showModal} style={{ color: "#ababab" }}>
          Asignar texto
        </p>
      )}

      <Modal
        title="Modificar texto"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Guardar
          </Button>,
        ]}
      >
        <Input
          defaultValue={value}
          onChange={(e) => (refValue.current = e.target.value)}
        />
      </Modal>
    </>
  );
}

export default CampoTextoComponente;
