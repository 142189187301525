import { Button, message, Select } from "antd";
import Modal from "antd/lib/modal/Modal";

import React from "react";
import { Api } from "../../../../../../../api/configApi";

const CampoOpcionMultipleComponente = (props) => {
  const actualizar = (data) => {
    Api(
      "listados",
      "UpdateItem",
      {
        actualizarItem: data,
      },
      {},
      "https://f399-181-132-3-197.ngrok.io"
    ).then((res) => {
      message.success("Se actualizó el campo");
      props.Get();
    });
  };

  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [opcionSeleccionada, setOpcionSeleccionada] = React.useState(
    props.opcionDefecto
  );
  const refOpcionSeleccionada = React.useRef(props.opcionDefecto);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    if (refOpcionSeleccionada.current !== props.opcionDefecto) {
      setOpcionSeleccionada(refOpcionSeleccionada.current);
      actualizar({
        valor: refOpcionSeleccionada.current,
        idCampo: props.id,
        idListado: props.idListado,
        nombreCampo: props.nombreCampo,
      });
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onSelect = (opcionSelect) => {
    refOpcionSeleccionada.current = opcionSelect;
  };

  return (
    <>
      {props.opcionDefecto !== "" ? (
        <p onClick={showModal}>{opcionSeleccionada}</p>
      ) : (
        <p onClick={showModal} style={{ color: "#ababab" }}>
          Asignar opción
        </p>
      )}

      <Modal
        title="Modificar opción"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Guardar
          </Button>,
        ]}
      >
        <Select
          onSelect={onSelect}
          defaultValue={props.opcionDefecto}
          style={
            props.opcionDefecto == "Agregar opción"
              ? { color: "#ababab", width: "100%" }
              : { width: "100%" }
          }
        >
          {props.opciones.map((opcion, index) => (
            <Select.Option key={index} value={opcion.opcion}>
              {opcion.opcion}
            </Select.Option>
          ))}
        </Select>
      </Modal>
    </>
  );
};

export default CampoOpcionMultipleComponente;
