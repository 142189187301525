import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Select,
  Switch,
} from "antd";
import React from "react";
import { Api } from "../../../../../../api/configApi";
import { store } from "../../../../../../store";

const { Option } = Select;

function FormAgregarItemListado(props) {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    console.log();
  }, []);
  function crearItem(values) {
    setLoading(true);
    Api(
      "listados",
      "InsertListado",
      {
        afiliado: props.afiliado,
        idListado: store.listados.find((e) => e.nombre === props.listado)._id,
      },
      values
    ).then((res) => {
      setLoading(false);
      if (res.success) {
        message.success("Listado guardado");
      } else {
        message.error("Error al guardar el listado");
      }
      props.Get();
      props.setVisible(false);
    });
  }
  return (
    <Form form={form} onFinish={crearItem} layout="vertical">
      {store.listados
        .find((e) => e.nombre === props.listado)
        .campos.map((e) => (
          <Form.Item
            label={e.NombreCampo}
            name={e.NombreCampo}
            rules={[{ required: e.obligatorio, message: "Campo obligatorio" }]}
          >
            {e.tipoCampo === "fecha" ? (
              <DatePicker showTime style={{ width: "100%" }} />
            ) : e.tipoCampo === "numero" ? (
              <InputNumber style={{ width: "100%" }} type="number" />
            ) : e.tipoCampo === "multiple" ? (
              <Select style={{ width: "100%" }}>
                {e.opciones.map((e) => (
                  <Option value={e.opcion}>{e.opcion}</Option>
                ))}
              </Select>
            ) : e.tipoCampo === "switch" ? (
              <Switch />
            ) : e.tipoCampo === "texto" ? (
              <Input />
            ) : (
              (e.tipoCampo === "imagen" || e.tipoCampo === "url") && (
                <Input type="url" />
              )
            )}
          </Form.Item>
        ))}
      <Button loading={loading} htmlType="submit">
        Guardar
      </Button>
    </Form>
  );
}

export default FormAgregarItemListado;
