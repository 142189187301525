import React from "react";
import { Button, Popover, Input, Space, message } from "antd";
import "antd/dist/antd.css";
import Modal from "antd/lib/modal/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { Api } from "../../../../../../../api/configApi";

const CampoImagenComponente = (props) => {
  const [visible, setVisible] = React.useState(false);
  const [value, setValue] = React.useState(props.valor);
  const [inputVisible, setInputVisible] = React.useState(false);
  const refImagenPorGuardar = React.useRef(props.valor); //Se me ocurrió una idea para el formulario del uploadWidget
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const refControlModificar = React.useRef(true);

  const actualizar = (data) => {
    Api(
      "listados",
      "UpdateItem",
      {
        actualizarItem: data,
      },
      {},
      "https://f399-181-132-3-197.ngrok.io"
    ).then((res) => {
      message.success("Se actualizó el campo");
      props.Get();
    });
  };

  const modificar = () => {
    setInputVisible(true);
    refControlModificar.current = !refControlModificar.current;
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (
      refImagenPorGuardar.current &&
      refImagenPorGuardar.current !== props.opcionDefecto
    ) {
      actualizar({
        valor: refImagenPorGuardar.current,
        idCampo: props.id,
        idListado: props.idListado,
        nombreCampo: props.nombreCampo,
      });
      setValue(refImagenPorGuardar.current);
      setVisible(false);
      setInputVisible(false);
      refControlModificar.current = true;
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setInputVisible(false);
    refControlModificar.current = true;
  };

  return (
    <>
      {props.valor !== "" ? (
        <FontAwesomeIcon icon={faImage} onClick={showModal} />
      ) : (
        <p onClick={showModal} style={{ color: "#ababab" }}>
          Asignar imagen
        </p>
      )}
      <Modal
        title="Modificar URL de la imagen"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancelar
          </Button>,
          refControlModificar.current && (
            <Button onClick={modificar}>Modificar</Button>
          ),
          <Button key="submit" type="primary" onClick={handleOk}>
            Guardar
          </Button>,
        ]}
      >
        {props.valor !== "" ? (
          <img src={value} style={{ width: "100%" }}></img>
        ) : (
          <p>Clic en el botón Modificar para asignar una imagen</p>
        )}

        <div style={{ margin: "20px 0px 20px 0px" }}></div>
        {inputVisible ? (
          <>
            <label htmlFor="input">Elige la nueva URL para la imagen:</label>
            <Input
              onChange={(e) => (refImagenPorGuardar.current = e.target.value)}
              defaultValue={value}
              id="input"
              type="url"
            ></Input>
            <div style={{ margin: "0px" }}></div>
          </>
        ) : (
          ""
        )}
      </Modal>
    </>
  );
};

export default CampoImagenComponente;
