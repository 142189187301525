import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  store,
  setCuentaActiva,
  setMostrarmenu,
  setNombrecuenta,
  setStoreFlujos,
  setStoreActualizarLaEstructura,
  setPensando,
} from "../store";
import { useHistory } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Api } from "../api/configApi";

function Abrircuenta(props) {
  const [nombredecuenta, setNombredecuenta] = useState("");
  const [creandocuenta, setCreandocuenta] = useState(false);
  const history = useHistory();
  function crearcuenta() {
    setCreandocuenta(true);
    var respu;
    Api("cuentas", "Insert", {
      nombre: nombredecuenta,
      admin: store.user.id,
      estado: "activo",
    })
      .then((resp) => {
        console.log(resp);
        respu = resp;
      })
      .then((res) => {
        console.log(res);
        setNombrecuenta(nombredecuenta);
        setCuentaActiva(respu.insertedId);
        setPensando(false);
        setMostrarmenu(true);
        setStoreActualizarLaEstructura(false);
        setCreandocuenta(false);
        setStoreFlujos(respu.ops[0].flujos);
        history.push("/");
      })
      .catch((err) => {
        console.log(err);
      })
      .catch((err) => {
        console.log(err);
        setCreandocuenta(false);
      });
  }
  useEffect(() => {}, []);

  return (
    <div
      style={{
        height: "100%",
        alignItems: "center",
        margin: "auto",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Backdrop style={{ zIndex: 3 }} open={creandocuenta}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 10,
          width: 350,
        }}
      >
        <TextField
          style={{ marginTop: 10 }}
          value={nombredecuenta}
          label="Nombre Cuenta (Empresa, marca)"
          variant="outlined"
          type="email"
          onChange={(va) => {
            setNombredecuenta(va.target.value);
          }}
        />
        <Button
          onClick={() => {
            crearcuenta();
          }}
          style={{ marginTop: 15 }}
          variant="contained"
          color="primary"
        >
          Entrar al panel
        </Button>
      </div>
    </div>
  );
}

export default Abrircuenta;
