import {
  Button,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Switch,
} from "antd";
import { set } from "mobx";
import React from "react";
import { store } from "../../../store";
import AccionesEtapa from "./accionesEtapa";

const { Option } = Select;

function RenderAcciones(props) {
  const [visible, setVisibleDrawer] = React.useState(false);
  const [listado, setListado] = React.useState({});
  const resetAccion = React.useRef(null);
  const [eliminar, setEliminar] = React.useState(false);
  const agregarCamposArbitrariosAccion = React.useRef(null);
  const [editando, SetEditando] = React.useState(false);
  const [form] = Form.useForm();

  function guardarConfiguracionItem(values) {
    agregarCamposArbitrariosAccion.current(values, cerrarModal);
    SetEditando(false);
    form.resetFields();
  }

  function cerrarModal() {
    setVisibleDrawer(false);
  }

  function cancelar() {
    resetAccion.current();
    form.resetFields();
    setVisibleDrawer(false);
  }

  React.useEffect(() => {
    if (editando) {
      console.log(props.objetoDisparador.listado);
      console.log(listado.idListado);
      form.setFieldsValue(listado.newItem);
    }
  }, [editando]);

  return (
    <>
      <Drawer
        visible={visible}
        title={listado.listado}
        destroyOnClose
        closeIcon
      >
        {!eliminar ? (
          <Form
            form={form}
            onFinish={guardarConfiguracionItem}
            layout="vertical"
          >
            {store.listados
              .find((e) => e._id === listado?.idListado)
              ?.campos.map((e) => (
                <Form.Item
                  label={e.NombreCampo}
                  name={e.NombreCampo}
                  rules={[
                    { required: e.obligatorio, message: "Campo obligatorio" },
                  ]}
                  key={e.NombreCampo}
                >
                  {e.tipoCampo === "fecha" ? (
                    <DatePicker showTime style={{ width: "100%" }} />
                  ) : e.tipoCampo === "numero" ? (
                    <InputNumber style={{ width: "100%" }} />
                  ) : e.tipoCampo === "multiple" ? (
                    <Select style={{ width: "100%" }}>
                      {e.opciones.map((e) => (
                        <Option value={e.opcion}>{e.opcion}</Option>
                      ))}
                    </Select>
                  ) : e.tipoCampo === "switch" ? (
                    <Switch />
                  ) : e.tipoCampo === "texto" ? (
                    <Input />
                  ) : e.tipoCampo === "imagen" ? (
                    e.tipoCampo === "imagen"
                  ) : (
                    <Input type="url" />
                  )}
                </Form.Item>
              ))}
            <Space>
              {!editando && <Button onClick={cancelar}>Cancelar</Button>}
              <Button htmlType="submit">Guardar</Button>
            </Space>
          </Form>
        ) : (
          <Form
            onFinish={guardarConfiguracionItem}
            layout="vertical"
            form={form}
          >
            <Form.Item name="itemEliminar" label="¿Cual eliminamos?">
              <Select>
                {props.disparador === "listados" &&
                  props.objetoDisparador.listado === listado.idListado && (
                    <Option value="itemActual">Item actual</Option>
                  )}

                <Option value="ultimoItem">Último item agregado</Option>
                <Option value="primerItem">Primer item agregado</Option>
              </Select>
            </Form.Item>
            <Space>
              {!editando && <Button onClick={cancelar}>Cancelar</Button>}
              <Button htmlType="submit">Guardar</Button>
            </Space>
          </Form>
        )}
      </Drawer>

      {props.objetoAcciones.map((accion) => {
        return (
          <AccionesEtapa
            setVisibleDrawer={setVisibleDrawer}
            setEliminar={setEliminar}
            setListado={setListado}
            SetEditando={SetEditando}
            disparador={props.disparador}
            objetoDisparador={props.objetoDisparador}
            EliminarAccion={props.EliminarAccion}
            accion={accion.accion}
            objetoAcciones={props.objetoAcciones}
            setObjetoAcciones={props.setObjetoAcciones}
            elid={accion.id}
            key={accion.id}
            resetAccion={resetAccion}
            agregarCamposArbitrariosAccion={agregarCamposArbitrariosAccion}
          />
        );
      })}
    </>
  );
}

export default RenderAcciones;
