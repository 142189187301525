import {
  faMinusCircle,
  faPencilAlt,
  faPencilRuler,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Drawer } from "antd";
import React from "react";
import ReactTags from "react-tag-autocomplete";
import { store } from "../../../store";

function AccionesEtapa(props) {
  const inputTagsa = React.useRef();
  const primeraCarga = React.useRef(true);
  const [lastags, setTags] = React.useState([]);
  const [placeholderOpciones, setPlaceholderOpciones] = React.useState();
  const [sugerencias, setSugerencias] = React.useState();
  const [parametroAbierto, setParametroabierto] = React.useState(false);
  const [tituloDrawer, setTituloDrawer] = React.useState("");
  const [visibleDrawer, setVisibleDrawer] = React.useState(false);

  const [newId, setNewId] = React.useState(
    "id" + Math.floor(Math.random() * (100000 - 1000)) + 1000
  );

  const limpiarAcciones = () => {
    props.EliminarAccion(props.elid);
    setTags([]);
    inputTagsa.current.setState({
      tags: [],
    });
  };

  const agregarCamposArbitrariosAccion = (values, cerrarModal) => {
    var laslasTags = [...lastags];
    laslasTags[0].newItem = values;
    setTags(laslasTags);
    primeraCarga.current = true;
    cerrarModal();
  };

  const actualizarAccionListado = (tag, tagTipo) => {
    if (tagTipo === "listado eliminar") {
      props.setEliminar(true);
    } else {
      props.setEliminar(false);
    }
    props.setListado(tag);
    props.agregarCamposArbitrariosAccion.current =
      agregarCamposArbitrariosAccion;
    props.SetEditando(true);
    props.setVisibleDrawer(true);
  };

  const ActualizarSugerencias = (tipo, element) => {
    const sugere = [];

    switch (tipo) {
      case "bandera":
        var newid = "id" + Math.floor(Math.random() * (100000 - 1000)) + 1000;

        var a = "";
        var s = [];
        element.forEach((el) => {
          a = a + " / " + el;
          s.push({
            id: newid,
            name: el,
            tipo: `bandera${el}`,
            valor: el,
            class: `bandera${el}`,
          });
        });
        setPlaceholderOpciones(a);
        setSugerencias(s);
        break;
      case "enviarNotificacion":
        var newid = "id" + Math.floor(Math.random() * (100000 - 1000)) + 1000;
        var s = [];
        var a = "";
        element.forEach((el) => {
          a = a + " / " + el;
          s.push({
            id: newid,
            name: el,
            tipo: `notificacion`,
            class: `notificacion`,
          });
        });
        setPlaceholderOpciones(a);
        setSugerencias(s);
        break;
      case "enviarEmail":
        var newid = "id" + Math.floor(Math.random() * (100000 - 1000)) + 1000;
        var s = [];
        var a = "";
        element.forEach((el) => {
          a = a + " / " + el;
          s.push({
            id: newid,
            name: el,
            valor: el,
            tipo: `email`,
            class: `email`,
          });
        });
        setPlaceholderOpciones(a);
        setSugerencias(s);
        break;

      case "atributos":
        var newid = "id" + Math.floor(Math.random() * (100000 - 1000)) + 1000;
        sugere.push({
          id: newid,
          name: `Posicionar en el ARBOL de red`,
          tipo: "posicionarEnRed",
        });
        sugere.push({
          id: newid,
          name: `Enviar notificacion`,
          tipo: "enviarNotificacion",
        });

        sugere.push({
          id: newid,
          name: `Enviar email`,
          tipo: "enviarEmail",
        });
        sugere.push({
          id: newid,
          name: `Levantar bandera`,
          tipo: "levantarBandera",
          opciones: ["Azul", "Amarilla", "Negra", "Verde", "Roja"],
        });

        store.losatributos.texts.forEach((atri) => {
          var newid = "id" + Math.floor(Math.random() * (100000 - 1000)) + 1000;
          sugere.push({
            id: newid,
            name: `Modificar su ${atri.nombre} por`,
            tipo: "atributo text",
            opciones: atri.opciones,
            campo: atri.nombre,
          });
        });

        store.listados.forEach((list) => {
          var newid = "id" + Math.floor(Math.random() * (100000 - 1000)) + 1000;
          sugere.push({
            id: newid,
            name: `Agregar un item al listado ${list.nombre}`,
            tipo: "listado agregar",
            listado: list.nombre,
            idListado: list._id,
            campos: list.campos,
          });
        });

        store.listados.forEach((list) => {
          var newid = "id" + Math.floor(Math.random() * (100000 - 1000)) + 1000;
          sugere.push({
            id: newid,
            name: `Eliminar un item al listado ${list.nombre}`,
            tipo: "listado eliminar",
            listado: list.nombre,
            idListado: list._id,
            campos: list.campos,
          });
        });

        store.losatributos.numbers.forEach((atri) => {
          var newid = "id" + Math.floor(Math.random() * (100000 - 1000)) + 1000;
          sugere.push({
            id: newid,
            name: `Modificar sus ${atri.nombre} por`,
            tipo: "atributo number",
            campo: atri.nombre,
          });
        });

        setSugerencias(sugere);
        break;
      case "text":
        var a = "";
        var s = [];
        var newid = "id" + Math.floor(Math.random() * (100000 - 1000)) + 1000;

        element.forEach((el) => {
          a = a + " / " + el;
          s.push({
            id: newid,
            name: el,
            tipo: "valor text",
            valor: el,
            class: "atributoNumero",
          });
        });
        s.push({
          id: newid,
          name: "INDEFINIDO",
          tipo: "valor text",
          valor: null,
          class: "atributoNumero",
        });
        a = a + " / INDEFINIDO";
        setPlaceholderOpciones(a);
        setSugerencias(s);
        break;
      case "aplicarOperacion":
        var a = "";
        var s = [];
        a = a + " Fin de la acción ";
        var newid = "id" + Math.floor(Math.random() * (100000 - 1000)) + 1000;

        if (lastags[lastags.length - 2]?.tipo !== "operadorAtributo") {
          element.forEach((el) => {
            a = a + " / " + el;
            s.push({
              id: newid,
              name: el,
              tipo: "operadorAtributo",
              valor: el,
              class: "atributoNumero",
            });
          });
        }

        setPlaceholderOpciones(a);
        setSugerencias(s);
        break;
      default:
        break;
    }
  };
  React.useEffect(() => {
    if (props.accion) {
      setTags(props.accion);
    }
  }, []);
  React.useEffect(() => {
    if (lastags.length === 0) {
      ActualizarSugerencias("atributos");
      setPlaceholderOpciones(
        " Modificar / Posicionar / Levantar Bandera / Enviar / Agregar Item / Eliminar Item"
      );
      return;
    }
    const ultimaTag = lastags[lastags.length - 1];
    var IndexAccion;
    var newAccion;

    switch (ultimaTag.tipo) {
      case "levantarBandera":
        setPlaceholderOpciones(" Azul / Amarilla / Negra / Verde / Roja");
        ActualizarSugerencias("bandera", ultimaTag.opciones);
        setParametroabierto(false);
        break;
      case "enviarNotificacion":
        var opciones = [];
        store.notificaciones.forEach((e) => opciones.push(e.titulo));

        setPlaceholderOpciones(opciones);
        ActualizarSugerencias("enviarNotificacion", opciones);
        setParametroabierto(false);
        break;
      case "enviarEmail":
        var lasoptions = [];
        store.emails.forEach((e) => lasoptions.push(e.asunto));

        setPlaceholderOpciones(lasoptions);
        ActualizarSugerencias("enviarEmail", lasoptions);
        setParametroabierto(false);
        break;
      case "atributo number":
        setPlaceholderOpciones(" + / - / Numero exacto / La variable / sus");
        setParametroabierto(true);
        break;
      case "atributo text":
        ActualizarSugerencias("text", ultimaTag.opciones);
        setParametroabierto(false);
        break;
      case "number":
      case "operarconvariable":
        setPlaceholderOpciones(" Fin de la acción");
        setSugerencias([]);
        setParametroabierto(false);
        newAccion = [...props.objetoAcciones];
        IndexAccion = newAccion.findIndex((acc) => {
          return acc.id === props.elid;
        });
        newAccion[IndexAccion] = { id: props.elid, accion: lastags };
        props.setObjetoAcciones(newAccion);
        break;
      case "por atributo number":
        setPlaceholderOpciones(
          " Fin de la acción / multiplicado / sumado / restado"
        );
        ActualizarSugerencias("aplicarOperacion", ["+", "-", "x"]);
        setParametroabierto(false);
        newAccion = [...props.objetoAcciones];
        IndexAccion = newAccion.findIndex((acc) => {
          return acc.id === props.elid;
        });
        newAccion[IndexAccion] = { id: props.elid, accion: lastags };
        props.setObjetoAcciones(newAccion);
        break;
      case "operadorAtributo":
        setPlaceholderOpciones("sus / Numero exacto");
        ActualizarSugerencias("operarCon", ["+", "-", "x"]);
        setParametroabierto(true);
        break;
      case "porcentajeCompra":
      case "valorPorcentaje":
        setPlaceholderOpciones(" Fin de la acción");
        setSugerencias([]);
        setParametroabierto(false);
        newAccion = [...props.objetoAcciones];
        IndexAccion = newAccion.findIndex((acc) => {
          return acc.id === props.elid;
        });
        newAccion[IndexAccion] = { id: props.elid, accion: lastags };
        props.setObjetoAcciones(newAccion);
        break;
      case "listado agregar":
      case "listado eliminar":
        setPlaceholderOpciones(" Fin de la acción");
        setSugerencias([]);
        setParametroabierto(false);
        if (!primeraCarga.current) {
          props.resetAccion.current = limpiarAcciones;
          props.agregarCamposArbitrariosAccion.current =
            agregarCamposArbitrariosAccion;
          props.setListado(ultimaTag);
          ultimaTag.tipo === "listado eliminar"
            ? props.setEliminar(true)
            : props.setEliminar(false);
          props.setVisibleDrawer(true);
        }

        setTituloDrawer(ultimaTag.listado.nombre);
        newAccion = [...props.objetoAcciones];
        IndexAccion = newAccion.findIndex((acc) => {
          return acc.id === props.elid;
        });
        newAccion[IndexAccion] = { id: props.elid, accion: lastags };
        props.setObjetoAcciones(newAccion);
        break;

      case "valor text":
      case "posicionarEnRed":
      case "banderaVerde":
      case "banderaNegra":
      case "banderaRoja":
      case "banderaAmarilla":
      case "banderaAzul":
      case "notificacion":
      case "email":
        setPlaceholderOpciones(" Fin de la acción");
        setSugerencias([]);
        setParametroabierto(false);
        newAccion = [...props.objetoAcciones];
        IndexAccion = newAccion.findIndex((acc) => {
          return acc.id === props.elid;
        });
        newAccion[IndexAccion] = { id: props.elid, accion: lastags };
        props.setObjetoAcciones(newAccion);
        break;
      default:
        break;
    }
  }, [lastags]);

  React.useEffect(() => {
    setTimeout(() => {
      primeraCarga.current = false;
    }, 1);
  }, []);

  const onInput = (value) => {
    if (!parametroAbierto || lastags.length === 0) {
      return;
    }
    const isnumber = Number(value);
    const queoperador = value.split("")[0];

    var operador = "replace";
    switch (queoperador) {
      case "+":
        operador = "sum";
        break;
      case "-":
        operador = "minus";
        break;
      default:
        break;
    }
    const nuevasSugerencias = [];

    store.losatributos.numbers.forEach((atri) => {
      var newid = "id" + Math.floor(Math.random() * (100000 - 1000)) + 1000;
      nuevasSugerencias.push({
        id: newid,
        name: `sus ${atri.nombre}`,
        tipo: "por atributo number",
        campo: atri.nombre,
        valor: atri.nombre,
      });
    });

    if (props.objetoDisparador?.variablesDisparador) {
      props.objetoDisparador?.variablesDisparador
        .filter((e) => e.tipo === "numero")
        .forEach((e) => {
          nuevasSugerencias.push({
            id: Math.random().toString(36).substring(2, 15),
            operador: "replace",
            name: "La variable " + e.nombreVariable,
            tipo: "operarconvariable",
            variable: e.nombreVariable,
            campo: e.elcampo,
            class: "atributoNumero",
            valor: parseFloat(value),
          });
          nuevasSugerencias.push({
            id: Math.random().toString(36).substring(2, 15),
            operador: "sum",
            name: "+ La variable " + e.nombreVariable,
            tipo: "operarconvariable",
            variable: e.nombreVariable,
            campo: e.elcampo,
            class: "atributoNumero",
            valor: parseFloat(value),
          });

          nuevasSugerencias.push({
            id: Math.random().toString(36).substring(2, 15),
            operador: "minus",
            name: "- La variable " + e.nombreVariable,
            tipo: "operarconvariable",
            variable: e.nombreVariable,
            campo: e.elcampo,
            class: "atributoNumero",
            valor: parseFloat(value),
          });
        });
      setSugerencias(nuevasSugerencias);
    }

    if (isnumber || isnumber === 0) {
      if (lastags[lastags.length - 1]?.tipo === "operadorAtributo") {
        nuevasSugerencias.push({
          id: "09949322",
          name: value,
          tipo: "number",
          class: "atributoNumero",
          valor: parseFloat(value),
        });
        nuevasSugerencias.push({
          id: "09949322",
          name: value + "%",
          tipo: "valorPorcentaje",
          class: "atributoNumero",
          valor: parseFloat(value),
        });
      } else {
        nuevasSugerencias.push({
          id: "09949322",
          operador: operador,
          name: value,
          tipo: "number",
          class: "atributoNumero",
          valor: parseFloat(value),
        });
      }

      if (props.disparador === "compra") {
        nuevasSugerencias.push({
          id: "09949320",
          operador: operador,
          name: value + "% del total de la compra",
          tipo: "porcentajeCompra",
          class: "atributoNumero",
          valor: parseFloat(value),
        });
      }

      setSugerencias(nuevasSugerencias);
    }
  };
  const onDelete = (i) => {
    const tags = lastags.slice(0);
    tags.splice(i, 1);
    setTags(tags);
    var newAccion = [...props.objetoAcciones];
    const IndexAccion = newAccion.findIndex((acc) => {
      return acc.id === props.elid;
    });
    newAccion[IndexAccion] = { id: props.elid };
    props.setObjetoAcciones(newAccion);
  };

  const onAddition = (tag) => {
    tag = sugerencias.find((e) => e.name === tag.name);

    const tags = [...lastags, tag];
    setTags(tags);
  };

  function TagComponent({ tag, removeButtonText, onDelete }) {
    var estilecho = "";
    const tiposListados = ["listado agregar", "listado eliminar"];
    switch (tag.tipo) {
      case "valor text":
      case "valor number":
      case "number":
      case "notificacion":
      case "email":
      case "porcentajeCompra":
      case "operarconvariable":
      case "por atributo number":
      case "valorPorcentaje":
        estilecho = "tagsValorText";
        break;
      case "and":
      case "operadorAtributo":
        estilecho = "tagsY";
        break;
      case "banderaAmarilla":
      case "banderaNegra":
      case "banderaAzul":
      case "banderaRoja":
      case "banderaVerde":
        estilecho = tag.tipo;
        break;
      default:
        estilecho = "tagsAccionDefault";
        break;
    }
    return (
      <>
        <span
          className={estilecho}
          style={{ marginRight: 5, marginBottom: 8, padding: 10 }}
        >
          {tag.name}
          {tiposListados.includes(tag.tipo) && (
            <FontAwesomeIcon
              onClick={() => actualizarAccionListado(tag, tag.tipo)}
              icon={faPencilAlt}
              style={{ marginLeft: 10, cursor: "pointer" }}
            />
          )}
        </span>
      </>
    );
  }

  return (
    <div
      id={newId}
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Drawer
        title={tituloDrawer}
        visible={visibleDrawer}
        onClose={() => setVisibleDrawer(false)}
      ></Drawer>
      <FontAwesomeIcon
        onClick={() => {
          props.EliminarAccion(props.elid);
        }}
        style={{ cursor: "pointer", marginTop: 5 }}
        icon={faMinusCircle}
      />
      <div style={{ width: "100%" }}>
        <ReactTags
          minQueryLength={1}
          onInput={onInput}
          ref={inputTagsa}
          tags={lastags}
          suggestions={sugerencias}
          onDelete={onDelete.bind(this)}
          onAddition={onAddition.bind(this)}
          placeholderText={placeholderOpciones}
          autoresize={false}
          allowUnique={false}
          tagComponent={TagComponent}
        />
      </div>
    </div>
  );
}

export default AccionesEtapa;
