import React from "react";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import { motion } from "framer-motion";

function TablaBase(props) {
  const [keys, setKeys] = React.useState([]);
  var key;
  React.useEffect(() => {
    const nuevaskeys = [];
    setKeys([]);
    for (key in props.elementos[0]) {
      if (key !== "_id") {
        nuevaskeys.push(key);
      }
    }
    setKeys(nuevaskeys);
  }, [props]);
  return (
    <table>
      <thead>
        <tr>
          {keys.map((elemento, index) => {
            return (
              <th style={{ textTransform: "capitalize" }} key={index}>
                {elemento}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {props.elementos.map((el, index) => {
          return (
            <motion.tr
              whileHover={{  backgroundColor: "rgb(245 245 245)", cursor: "pointer" }}
              key={index}
              onClick={()=>{ console.log(el.accion()); }}
            >
              {keys.map((elem, elindex) => {
                if (typeof el[elem] === "object") {
                  return (
                    <th style={{ maxWidth: "10%" }} key={elindex}>
                      {el[elem].map((a) => {
                        return (
                          <div>
                            <Chip
                              avatar={
                                <Avatar style={{ textTransform: "capitalize" }}>
                                  {a.nombre.charAt(0)}
                                </Avatar>
                              }
                              label={a.nombre}
                              onClick={() => {
                                console.log("Vea ps");
                              }}
                            />
                          </div>
                        );
                      })}
                    </th>
                  );
                } else {
                  return (
                    <th key={elindex}>
                      <span
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          color: "#526484",
                        }}
                      >
                        {" "}
                        {el[elem]}{" "}
                      </span>
                    </th>
                  );
                }
              })}
            </motion.tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default TablaBase;
