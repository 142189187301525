import { Button, message, Popover, Switch } from "antd";
import React from "react";
import { Api } from "../../../../../../../api/configApi";

const CampoSwitchComponente = (props) => {
  const refValue = React.useRef(props.valor);

  const actualizar = (data) => {
    Api(
      "listados",
      "UpdateItem",
      {
        actualizarItem: data,
      },
      {},
      "https://f399-181-132-3-197.ngrok.io"
    ).then((res) => {
      message.success("Se actualizó el campo");
      props.Get();
    });
  };

  function onChange(checked) {
    refValue.current = checked;
    actualizar({
      valor: refValue.current,
      idCampo: props.id,
      idListado: props.idListado,
      nombreCampo: props.nombreCampo,
    });
  }

  return (
    <>
      <Switch defaultChecked={refValue.current} onChange={onChange} />
    </>
  );
};

export default CampoSwitchComponente;
