import React from "react";
import "./uploadFileDesignWidget.css";
import { Widget } from "@uploadcare/react-widget";
import { Button, message, Skeleton } from "antd";
import { Api } from "../../../../../api/configApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faUpload } from "@fortawesome/free-solid-svg-icons";
import { PowerOffOutlined } from "@material-ui/icons";

const ButtonViewUploadFileDesignWidget = (props) => {
  let [expire, setExpire] = React.useState();
  let [signature, setSignature] = React.useState();
  const [publicKey, setPublicKey] = React.useState("");
  const [archivoSubido, setArchivoSubido] = React.useState(false);
  const [subiendo, SetSubiendo] = React.useState(false);
  const [tituloBoton, setTituloBoton] = React.useState(
    props.propiedades.widget.titleChoice
  );

  const widgetApi = React.useRef();

  React.useEffect(() => {
    generarToken();
  }, []);

  function enviarInfoBack(data) {
    console.log(data);
    try {
      Api(
        "uploadFiles",
        "recibirParmsItemList",
        {},
        { data },
        "http://89ab-181-132-3-197.ngrok.io"
      ).then((res) => {
        setArchivoSubido(true);
        SetSubiendo(false);
      });
    } catch (err) {
      console.log(err);
    }
  }

  function generarToken() {
    try {
      Api(
        "uploadFiles",
        "generateToken",
        {},
        {},
        "http://89ab-181-132-3-197.ngrok.io"
      ).then((res) => {
        setPublicKey(res.publicKey);
        setExpire(res.expire);
        setSignature(res.token);
      });
    } catch (err) {
      console.log(err);
    }
  }

  const refDatosEnviar = React.useRef({});

  //Función que permite restringir los tipos de datos
  function admitedTypes(type) {
    return function (fileInfo) {
      var fileInform = fileInfo.mimeType;
      if (fileInform !== null) {
        if (!type.includes(fileInform)) {
          throw new Error("type");
        }
      }
    };
  }

  const translations = {
    buttons: {
      cancel: "Cancel",
      remove: "Remove",
      choose: {
        files: {
          one: props.propiedades.widget.titleChoice,
          other: props.propiedades.widget.titleChoice,
        },
        images: {
          one: props.propiedades.widget.titleChoice,
          other: props.propiedades.widget.titleChoice,
        },
      },
    },
    errors: {
      type: "Formato inválido",
    },
  };

  return (
    <div>
      {signature ? (
        !archivoSubido ? (
          <>
            <Button
              loading={subiendo}
              icon={
                <FontAwesomeIcon icon={faUpload} style={{ marginRight: 10 }} />
              }
              onClick={() => widgetApi.current.openDialog()}
            >
              {tituloBoton}
            </Button>
            <Widget
              ref={widgetApi}
              id="file"
              locale="es"
              name="file"
              publicKey={publicKey}
              multiple={props.propiedades.widget.upMultiple}
              onFileSelect={(file) => {
                SetSubiendo(true);
                if (!props.propiedades.widget.upMultiple) {
                  file
                    .then((res) => {
                      refDatosEnviar.current.idWidget =
                        props.propiedades.widget._id;
                      refDatosEnviar.current.urls = [res.cdnUrl];
                      enviarInfoBack(refDatosEnviar.current);
                    })
                    .catch((err) => {
                      message.error("No pudimos subir tu archivo");
                      console.log(err);
                      setArchivoSubido(false);
                      SetSubiendo(false);
                    });
                } else {
                  let arrayUrls = [];
                  file.files().forEach((archi) => {
                    archi.then((el) => {
                      arrayUrls.push(el.cdnUrl);
                    });
                  });

                  file
                    .promise()
                    .then((el) => {
                      refDatosEnviar.current.idWidget =
                        props.propiedades.widget._id;
                      refDatosEnviar.current.urls = arrayUrls;
                      enviarInfoBack(refDatosEnviar.current);
                    })
                    .catch((err) => {
                      message.error("No pudimos subir tus archivos");
                      setArchivoSubido(false);
                      SetSubiendo(false);
                      console.log(err);
                    });
                }
              }}
              validators={[
                admitedTypes(props.propiedades.widget.formatOptions),
              ]}
              localeTranslations={translations}
              secureSignature={signature}
              secureExpire={expire}
            />
          </>
        ) : (
          <FontAwesomeIcon icon={faCheckCircle} color="green" />
        )
      ) : (
        <Skeleton
          active
          paragraph={{ width: 100, rows: 1 }}
          style={{ width: "100%" }}
        />
      )}
    </div>
  );
};

export default ButtonViewUploadFileDesignWidget;
